.SupplimentaryMemberOpeninBalance .BackgroundColor {
    background-color: rgb(132, 126, 129);
    border: 3px solid grey;
    height: 600px;
    border-radius: 6px;
}

.SupplimentaryMemberOpeninBalance .p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid rgb(11 11 12);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
    border-radius: 6px;
    border-color: lightgray;

}

.SupplimentaryMemberOpeninBalance .p-inputtext {
    margin: 0;
    width: 120px;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.SupplimentaryMemberOpeninBalance .Form_inline label {
    width: 150px;
}

.SupplimentaryMemberOpeninBalance .p-dropdown {
    background: #ffffff;
    border: 1px solid #262729;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    width: 120px;
}

.SupplimentaryMemberOpeninBalance .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 1px 1px 1px 1px;
}

.SupplimentaryMemberOpeninBalance .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.SupplimentaryMemberOpeninBalance .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    border-width: 1px;
}

.SupplimentaryMemberOpeninBalance .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    border-width: 0 0 1px 0;
    padding: 0.5rem 1rem;
    outline-color: #a6d5fa;
    background: #E2EBF0;
}