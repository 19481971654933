.TrReview{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
  
  .TrReview label {
    margin: 5px 10px 5px 0;
  }
  .TrReview .Created {
    background-color: turquoise;
  }
  .TrReview .Missing {
    background-color: lightsalmon;
  }
  