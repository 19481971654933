.GLReport .GLReportCard {
    margin: 2px;
     height:auto;
     border: black solid 3px;
     background-color: rgb(214, 220, 231);
}
.GLReport .ButtonCard{
   margin: 5px;
   height: 3.5cm;
   border: black solid 2px;
   background-color: rgb(214, 220, 231);
}
/* .GLReport label{
    margin:10px;
     
 } */
 .GLReport .Date{
     height:30px;

 }
 .GLReport .number{
    height:30px;
    width: 100px;

}
.GLReport .title{
    height:30px;
    width: 400px;
    margin: 10px;

}
.GLReportCard .ButtonCard Button{
    height: 30px;
    border-radius: 10px;
}

.GLReportCard .ButtonCard label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14px;
}
.GLReportGrid .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
   
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.GLReportGrid .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
   font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1rem 1rem;
    outline-color: #a6d5fa;
}
