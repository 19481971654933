.TenantDetails .invalid-feedback{
    font-size: 16px;
    text-align: center;
    justify-content: center;    
    color: red;
    margin: 10px;
  }
  .TenantDetails .BackgrdColor{
    background-color: lightgrey;
    border: black;
  }
  .TenantDetails .Date{
    height:30px;
    width:200px;
    margin:10px;
    border-radius: 6px;
    border-color: lightgray;

  }
  .highlighted-row {
    background-color: blue;
}

.selectedRow {
  /* background-color: rgba(6, 238, 45, 0.932) !important; */
  background-color: #93B88B !important;
}
.TenantDetails .dropdown {
  width: 200px;
    margin: 10px;
    padding: 6px 4px;
    border-radius: 6px;
    border-color: lightgray;
}

.TenantDetails .label{
  width:200px;
  margin: 10px;
  padding: 6px;
}
.TenantDetailsLabel .checkbox{
  margin: 60px;
  padding: 10px;
    border: 4px solid #ced4da;
    background: #ffffff;
    width: 50px;
    height: 30px;
    color: #495057;
    border-radius: 3px;
    margin-left: 20px;
}

.TenantDetails .EmailTextBox
{
  width:560px;
  height: 30px;
  display: flex;
  border-radius: 6px;
  border-color: lightgray;
}
.TenantDetailsLabel{   
  font-size: 13px;
   color: rgb(11, 11, 12);
   height: 20px;
   width: 170px;
   margin-top: 10px;
   text-align: right;
   float: left;
   
 }
 .TenantDetails .Mobile{
  font-size: 13px;
   color: rgb(11, 11, 12);
   height: 20px;
   width: 170px;
   margin-top: 10px;
   text-align: right;
   float: left;

 }

.TenantSecondAddress{
  margin-left: 169px;
  width:1500px;
  border-radius: 6px;
  border-color: lightgray;
  
}
input[type7=text]{
  width: 1100px;
  padding: 6px 4px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
   
}
.TenantDetails .tenantlbl{
  height: 28px;
  width: 990px;
  border-radius: 6px;
  border-color: lightgray;
  text-transform: capitalize;
}
input[type6=text]{
  width: 150px;
  padding: 6px 4px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
  height: 28px;
}

.TenantDetails .p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    /* background: #ffffff; */
    width: 30px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    /* margin-left: 10px; */
}
.TenantDetails .Form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 6px;
}
.TenantDetails .status{
  height: 28px;
  width: 200px;
  border-radius: 6px;
  border-color: lightgray; 
}
.TenantDetails .occupiedbyfld{
  height: 28px;
  width: 200px;
  border-radius: 6px;
  border-color: lightgray; 
  
}
.TenantDetails .SelectSmall{
  width: 105px;
  height:30px;
  border-radius: 6px;
  border-color: lightgray;
  margin: 8px;


}