.BillGeneration {
  background-color: rgb(229, 238, 233);
}
@media screen and (min-width: 415px) {
  .site-container {
    flex-direction: row;
  }
}

.BillGeneration label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;

}
.BillGeneration Button {
  height: 30px;
  border-radius: 10px;
}

.billTable {
  width: 1200px;
}

.container {
  height: 100%;
}

.MediumTextBox {
  width: 390px;
  height: 25px;
}

.BillGeneration .p-col-fixed {
  padding: 20px;
  padding-left: 20px;
  width: 200px;
  margin: 0px;

}

.DateBox {
  width: 150px;
  height: 25px;
  border-radius: 6px;
  border-color: lightgray;
}

.TextBoxSize50 {
  width: 50px;
  height: 25px;
  /* border-radius: 6px; */

}

.BillGeneration .TextBoxSize100 {
  width: 100px;
  height: 25px;
}

.TextBoxSize150 {
  width: 150px;
  height: 25px;
  border-radius: 6px;
  border-color: lightgray;

}

.TextBoxSize200 {
  width: 200px;
  height: 25px;

}

.Form_inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 7px;

}

.Form_inline label {
  margin: 5px 10px 5px 0;
}

.BillGeneration .billForm .ValueLabel {
  height: 20px;
  width: 230px;
  margin-top: 10px;
  text-align: right;
  float: left;
}

.BillGeneration .billForm .Label {
  width: 40px;
  margin: 5px;
}

.ManualBillLabel {
  width: 140px;
}

.Label70 {
  width: 75px;
}

.BillGeneration .p-col {
  padding-right: 20px;
}

.BillGeneration .billForm label {
  width: 80px;
  margin: 5px;

}
