.GeneralReceipt {
  background-color: #e7ecdd;
  border-radius: 2%;
  
}

.GeneralReceipt .label200 {
  width: 180px;
  font-size: 14px;
  color: rgb(39, 34, 34);
  height: 20px;
  margin-top: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  float: left;
  padding-left: 20px;
}

.popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}

.GeneralReceipt label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
}

.GeneralReceipt Button {
  height: 30px;
  border-radius: 10px;
}

.GeneralReceipt .label100 {
  width: 105px;
  color: rgb(39, 34, 34);
  height: 20px;
  margin-top: 5px;
  float: left;
  padding-left: 20px;
}

.GeneralReceipt .p-datatable .p-datatable-tbody > tr {
  background: linear-gradient(to bottom, #feffff 0%,#cbe6f0 35%);
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-family: Verdana, sans-serif;
}
.GeneralReceipt .height{
  width: 175px;
  borderRadius:6px;
   borderColor:lightgrey;
}
.GeneralReceipt .amount1
{
width: 175px;
border-radius: 6px;
border-color: lightgray;
height: 30px;
}
.GeneralReceipt .chq1{
  width: 175px;
border-radius: 6px;
border-color: lightgray;
height: 30px;
}
.GeneralReceipt .height2{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px;
}
.GeneralReceipt .p-formgroup-inline{
margin: -5px;
}
.GeneralReceipt .height3{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px;
}
.GeneralReceipt .smallTextBox{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px;
}