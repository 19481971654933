.SocietyInfo {
    background-color: rgb(229, 238, 233);
    border: 2px solid rgb(11, 11, 12); 
  }
  .BillFootnote {
    padding: 20px;
  }
  .WingMaster {
    background-color: rgb(229, 238, 233);
    height: 470px;
  border: 2px solid rgb(11, 11, 12);
  }
  .TextBox {
    width: 1000px;
    height: 27px;
    margin: 8px;
    box-sizing: border-box;
  }
  .SocietyName {
    margin-left: 35px;
  }
  .Form_inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    
  }
  .Form_inline label {
    margin: 5px 10px 5px 0;
  }
  .Form_inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
  
  }
  .NumberTextBox {
    width: 580px;
    height: 27px;
    margin: 8px;
    box-sizing: border-box;
  }
  .ShortWidthDate {
    width: 200px;
    height: 27px;
    margin: 8px;
  }
  .SocietyCode {
    width: 210px;
    height: 27px;
    margin: 8px;
  }
  .ShortTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .ShortNumberTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .EmailId {
    width: 630px;
    height: 27px;
    margin: 8px;
  }
  
  .SelectMediumWidth {
    width: 200px;
    height: 27px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .RateLabel {
    margin-left: -40px;
  }
  .divider {
    border: 5px solid rgb(11, 11, 12); 
  }
  .LabelWidth200 {
    width: 210px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 10px;
    text-align: right;
    float: left;
  
  }
  .LabelWidth50 {
    width: 50px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 10px;
    text-align: left;
    
  
  }
  /* .checkboxLabel1{
    width: 150px;
    height: 25px;
  } */
  .checkboxLabel {
    width: 95px;
    font-size: 16px;
    color: rgb(39, 34, 34);
  }
  /* .checkboxLabel .Checkbox {
    width: 15px;
    height: 25px;
  } */
  .checkboxLabel1 {
    width: 140px;
    font-size: 16px;
    color: rgb(39, 34, 34);
  }
  .RadiobuttonLabel {
    width: 250px;
    text-align: center;
  }
  
  Button.label{
   transition: 'all .2s';
   margin-top:'15px';
   padding:'25px 15px';
   width:'130px';
   height:'30px';
  }
  .Select {
    width: 100px;
    height: 27px;
  }
  .WingMaster .tableheader {
    justify-content: space-between;
    display: flex;
  }
  .SocietyInfo.label {
    color: rgb(39, 34, 34);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    height: 13px;
  
  }
  .Slct2{
   height: 27px;
   width: 125px;
   border-radius: 6px;
   border-color: lightgray;
  }
  .checkboxLabel {
    width: 95px;
    font-size: 13px;
    color: rgb(39, 34, 34);  
  }
  .chkboxlabel{
    font-size: 13px;
    width: 120px;
    color: rgb(39, 34, 34);
  }