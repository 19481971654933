.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% ;
    height: 1000px;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* font-size: 12px; */
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    font-size: 13px;
    border: 1px solid rgb(39 34 34);
    /* padding: 8px; */
  }
  
  /* tr:nth-child(even) {
    background-color: #dddddd;
  } */
  .SocietyName{
      text-align: center;
      margin-top: 20px;
      

  }
  .border{
    border-style: solid; 
    margin-left: 10px;
    margin-right: 10px;
    /* height:80px; */
    height:auto;
  }
  .alignleft {
    /* float: left; */
    /* text-align:left;*/
    margin-left: 10px; 
    display: inline-flex;
    justify-content: flex-start ;
  }
  .aligncenter {
    /* float: center; */
    /* width:33.33333%; */
    /* text-align:center; */
    display: inline-flex;
    justify-content: center;

    
  }
.alignright {
    float: right;
    
    margin-right: 10px;
    display:inline-flex;
    justify-content: flex-end ;
}
.Table{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
    
}
.BillFootNote{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
    padding: 4px;
}
.Receipt
{
  height: auto;
  margin-top: 2px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    padding: 4px;

}
/* .PageBreak {
  margin-top: 1rem;
    display: block;
    page-break-after: always;
} */

@media print {
  .PageBreak {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
    page-break-before: always;
  }
  
}
.mRRPageBreak{
  margin-top: 1rem;
  display: block;
  page-break-inside: avoid;

}
/* .RupeesInLetter{
  margin-right:60%;
  margin-bottom:-25px;
  font-size: 12px;
} */
.font{
  font-size:20px;
  font-weight: bold;
  
}
.Bill{
  text-align:center;
  font-size:20px;
  font-weight: bold;
}
.MemberBills .card{
 
  width:100%;
  /* background-color:rgba(238, 245, 244, 0.829) */
}
/* .MemberBillPrint .SelectionCard{
 
  background-Color: black;
  height: 45px;
} */
.DuesReminder{
  height:29.7cm;
}
.DuesReminder .Border{
  border-style:solid; 
    /* margin-left: 10px;
    margin-right: 10px;
     margin-top: 5px;
    margin-bottom: -35px;  */
    margin-top: 0.1cm;
    margin-bottom: 2cm;
    /* height: 32cm; */
    height:290mm;
    
    
   
    /* height:80px; */
}
.DuesReminder .font{
  font-size:20px;
  font-weight: bold;
}
.DuesReminder .fontsize{
  font-size:18px;
}
.DuesReminder .AlignContentEnd{
  text-align: end;
}
.DuesReminder .AlignContentCenter{
  text-align: center;
  
}
.DuesRegularTable{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border-style: solid; 
  font-size: 14px;
  height: auto;
}

.DuesAdvanceTable{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border: black solid 2px;
  font-size: 14px;
  height: auto;
}
.SummaryDues{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border-style: solid; 
  font-size: 14px;
  /* height: 297mm; */
  height:auto;
}

/* .page footer {
  font-size: 9px;
  color: #f00;
  text-align: center;
} */
.page footer {
  position: fixed;
  bottom: 0;
}

@page {

  size: A4;
  margin: 7mm 20mm 5mm 15mm;
  
 
}
@page :RegularBillRegister{

  size: landscape;
  margin: 7mm 20mm 5mm 15mm;
  
 
}

@media print {
  footer {
    position: fixed;
    bottom: 0;
  }


.pagebreak {
        break-after: always !important;
        page-break-after: always !important;
        page-break-inside: avoid !important;
    }

  html, body {
    width: 210mm;
    height: 297mm;
    
  }
}
.DuesReminder .card{
  height:60px;
  border: black solid 3px;
  background-color: rgb(214, 220, 231);
}
.Date{
  height:30px;

}
.headerColor{
  background-color: rgb(177, 177, 173);
}
/* label{
  margin:10px;
   
} */

.MemberBill Button{
  height: 30px;
  border-radius: 10px;
}
.MemberBill input[type='Date']{
  height: 30px;
  margin-left: 10px;
  border-radius: 10px;
}
.MemberBill .card{
  background-color: rgb(48, 52, 55);
  height:7vh;
}

.header{
  width: 100%;
  /* position: fixed; */
  padding: 25px 0;
}
.footer{
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
}
.MemberBills .Receipt label{
  width: 250px;
}
  
