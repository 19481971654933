
.Users{
    background: radial-gradient(ellipse at center, #feffff 0%,#d2ebf9 100%);
}

.Users .p-datatable .p-datatable-tbody > tr {
    background: linear-gradient(to bottom, #feffff 0%,#ddf1f9 35%);
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Verdana, sans-serif;
}