.bColor {
  background-color: #e7ecdd;
}

.MRReturnEntry Button {
  height: 30px;
  border-radius: 10px;
}

.MRReturnEntry label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  padding: 13;
  margin-top:5px;
  margin-left: 4px;
}

.popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}
.MRReturnEntry .smallTextBox{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px; 

}