.SocietyView .Editbtn {
  background-color: rgb(55, 0, 255);
  border: none;
  color: white;

  font-size: 16px;
  cursor: pointer;
  margin-inline: 5px;
}
.SocietyView .AddBtn {
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;

}
.SocietyView .table-header {
  display: flex;
  justify-content: space-between;
  
}

.SocietyView .table-header label {
  margin-inline: 10px;
  color: grey;

}

.SocietyView .p-datatable .p-datatable-tbody > tr > td {
  background: linear-gradient(to bottom, #feffff 0%,#ddf1f9 35%);
  font-size: 12px;
  font-family: Verdana;
  margin: 2px;
  height: 12px;
  padding: 10px;
  color: black;
  overflow: hidden;
}
