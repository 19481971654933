.SupplimentaryBillGenerationPlan {
    margin: 5px;
    padding: 5px;
}

.text-danger {

    color: red;
}

.SupplimentaryBillGenerationPlan label {

    width: '200px';
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    margin-top: 5px;
}

.SupplimentaryBillGenerationPlan input {

    width: '250px' !important;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
}

.SupplimentaryBillGenerationPlan div {

margin: 2px;
}


input[type="number"] {
    width: '250px';
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
    

}
input[type="date"] {
    width: '250px';
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;

}

.invalid {
    border: 1px solid #ff0000
}

.SupplimentaryBillGenerationPlan a {

    align-self: flex-start;
    text-align: left;
}