.Vehicleform .VehicleLabel{
    font-size: 13px;
    color: rgb(11, 11, 12);
    width: 250px;
    margin-top: 10px;
    margin-left: 20px; 
   height: 20px;
   text-align: left;
   float: right;
}
.Vehicleform .VehicleSelect{
    width: 250px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
}
.Vehicleform{
  
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgray;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
    

}
.Vehicleform .table-header .btn{
    margin-left: 1100px;
}
