.SupplimentaryBill{


}

.SupplimentaryBill  input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
  }
  .SupplimentaryBill .NumberTextBox {
    width: 580px;
    height: 27px;
    margin: 8px;
    box-sizing: border-box;
  }
  .SupplimentaryBill .ShortWidthDate {
    width: 200px;
    height: 27px;
    margin: 8px;
  }
  .SupplimentaryBill .SocietyCode {
    width: 210px;
    height: 27px;
    margin: 8px;
  }
  .SupplimentaryBill .ShortTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
  }
  .SupplimentaryBill .ShortNumberTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
  }

  .SupplimentaryBill .LabelWidth200 {
    width: 210px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 30px;
    margin-top: 10px;
    text-align: right;
    float: left;
    padding: 3px;
    margin: 3px;
  } 
  
  .SupplimentaryBill .SelectMediumWidth {
    width: 210px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 30px;
    margin-top: 10px;
    text-align: left;
    float: left;
    padding: 3px;
    margin: 3px;
  }