.SupplimentaryBill{
margin: 5px;
padding: 5px;
}
.text-danger{

    color: red;
}
.SupplimentaryBill label{

    width:'200px';
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    margin-top: 6px;


}
.SupplimentaryBill input{

    width:'250px' !important;
    height:30px;
    border-radius: 6px;
    border-color: lightgray;
}

input[type="number"] {
    width:'250px' ;
    height:30px;

}
.invalid{
    border:1px solid #ff0000
    }
.SupplimentaryBill a{

    align-self: flex-start;
    text-align: left;
}

.SupplimentaryBill .date{
    width: 195px;
    height: 28px;
    border-radius: 6px;
    border-color: lightgray;
}
.SupplimentaryBill .selectBillFor{
    border-radius: 6px;
    border-color: lightgray;
    border-left-color: black;
    border-top-color: black;
    /* width:'250px'; */
     height:'28px' ;
}