.Propertyform{
        color: lightgrey;
        /* border: 1px solid rgb(11, 11, 12); */
        background-color: lightgray;
        border-radius: 8px;
        
    
  }
  .Header{
    /* border: 1px solid rgb(11, 11, 12); */
    /* background-color: rgba(228, 239, 240, 0.548); */
    background-color: rgb(168, 219, 247);
    color: grey;
    margin-top: 5px;


  }
  .PropertyLabel{
    font-size: 13px;
    color: rgb(11, 11, 12);
    height: 20px;
    /* width: 100px; */
    margin-top: 10px;


    
    
    text-align: right;
    
    float:left;
}
.PropertySelect{
    width: 300px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
    height: 30px;
}
.Propertyform input[type8="text"]{
    width: 300px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    height: 30px;
  
}
.Propertyform input[type="number"]{
    width: 300px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    height: 30px;
}
.Propertyform .invalid-feedback{
    font-size: 16px;
    text-align: center;
    justify-content: center;    
    color: red;
    margin:10px;
}
.Propertyform .Form-inline label {
     margin: 5px 10px 5px 0; 
    width: 200px;
  }

  .Propertyform .Floorlabel{
    font-size: 13px;
    color: rgb(11, 11, 12);
    height: 20px;
    margin-left: 50px;
    margin-top: 10px;
  }
  .Propertyform .floor{
    height: 28px;
    border-radius: 6px;
    border-color: lightgrey;
    width: 300px;
    text-transform: capitalize;
  }