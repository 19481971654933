.BankDepositeSlip{
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    margin: 15px 30px;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid lightgray;
    /* font-size: 2px; */
} 

.BankDepositeSlip label {
    display: inline-block;
    font-size: 13px;
    height: 20px;
    margin: 7px;
    width: 90px;

    
    /* width: 100px; */
    /* margin-top: 10px;
    text-align: right;
    float: left; */
  }
  /* .BankDepositeSlip {
    display: flex;
    flex-flow: row wrap;

    border-radius: 4px;
  } */
  .BankDepositeSlip .largeTxtBox{
    width: 840px;
    border-radius: 6px;
    border-color: lightgray;
    margin: 4px;
    height: 25px;


  }
  .BankDepositeSlip .flatn{
    width: 70px;
  }
 
  .BankDepositeSlip .Name{
    width: 435px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .BankDepositeSlip .datee{
    width: 148px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
    margin: 5px;
  }
  .BankDepositeSlip .rmno{
    width: 95px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
    margin: 5px;

  }
  .BankDepositeSlip .btnn{
    margin: 6px;
    height: 28px;
    
  }
  .BankDepositeSlip .btn{
    margin: 8px;
    height: 28px;
    margin-bottom: 30px;
  }
  .BankDepositeSlip .chqdate{
    height: 28px;
    width: 200px;

  }
  .BankDepositeSlip .chqno{
    height: 28px;
    width: 300px;
  }
  .BankDepositeSlip .fltn{
    width: 75px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray; 
  }
  
.BankDepositeSlip .amt{
    width: 150px;
    border-radius: 6px;
    border-color: lightgray;
    height: 25px;
}
.BankDepositeSlip .chqn{
    width: 150px;
    border-radius: 6px;
    border-color: lightgray;
    height: 25px;  
}  
.BankDepositeSlip .amtt{
    width: 94px;
}
.BankDepositeSlip .Select4{
    width: 40px;
    border-radius: 6px;
    border-color: lightgray;
    height: 25px;
    padding-left: 6px;
}

