@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App .signUp{
  text-align: center;
}

.App .login{
  text-align: center;
}

.Nav{
  display: flex;
  justify-content: space-around;
  align-items:center;
  /* background: rgb(41, 40, 40); */
  background: linear-gradient(to top right, #336699 0%, #666699 100%);
  min-height: 7vh;
  color:white;
  
  font-family: ui-monospace;

}
.Menu{
  display: flex;
  justify-content: space-around;
  margin-left: 60%;
}

.card{
  margin-bottom: 0px;
  color:rgb(26, 24, 24);
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% ;
  height: 1000px;
}
.cardLogin {
  margin: 0 auto; 
  float: none; 
  margin-bottom: 10px; 
  width: 30rem ;
  margin-top: 5em;
  height: 20em;
  border-radius: 20;
  border: 2px solid black;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btnGoogle{
  background-color: red;
}
.p-column-filter{
  width:100%;
  height:27px;
}
.p-calendar {
  width: 240px;
  height: 30px;
  position: relative;
  display: inline-flex;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: none;
  border-image: initial;
}
.disabled {
  opacity: 0.65;
cursor: not-allowed;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #1c1e1f;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #080a0c;
  background: #bcb7bc;
  transition: box-shadow 0.2s;
}
/* .splitLeft {
  height: 1000%;
  width: 1000%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
} */








/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    /* padding: 0.75rem 1rem; */
    border-radius: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
   
    
}
.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #060607;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #d32f2f;
}
.Nav .UiName{
    /* border: 2px solid white; */
      background-color:white;
      color: black;
      /* border-width: 10px; */
      /* width:30% */
}
.Nav .societyName{
    width: 50%;
}
.Nav{
    width: 100%;
}

.card .p-menubar .p-submenu-list{
    padding: 0.25rem 0;
   
    /* font-family: 'Times New Roman', Times, serif; */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    /* rgb(101,166,145); */
    border: 0 none;
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
    width: 12.5rem;
}

.card .p-menubar .p-menuitem-link .p-menuitem-text {
    color:black;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #080808;
    margin-right: 0.5rem;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #080808;
    margin-left: 0.5rem;
}

/* .btn { display: inline-block; *display: inline; *zoom: 1; padding: 4px 10px 4px; margin-bottom: 0; font-size: 13px; line-height: 18px; color: #333333; text-align: center;text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75); vertical-align: middle; background-color: #f5f5f5; background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6); background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6); background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6)); background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6); background-image: -o-linear-gradient(top, #ffffff, #e6e6e6); background-image: linear-gradient(top, #ffffff, #e6e6e6); background-repeat: repeat-x; filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0); border-color: #e6e6e6 #e6e6e6 #e6e6e6; border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25); border: 1px solid #e6e6e6; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); cursor: pointer; *margin-left: .3em; }
.btn:hover, .btn:active, .btn.active, .btn.disabled, .btn[disabled] { background-color: #e6e6e6; }
.btn-large { padding: 9px 14px; font-size: 15px; line-height: normal; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.btn:hover { color: #333333; text-decoration: none; background-color: #e6e6e6; background-position: 0 -15px; -webkit-transition: background-position 0.1s linear; -moz-transition: background-position 0.1s linear; -ms-transition: background-position 0.1s linear; -o-transition: background-position 0.1s linear; transition: background-position 0.1s linear; }
.btn-primary, .btn-primary:hover { text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); color: #ffffff; }
.btn-primary.active { color: rgba(255, 255, 255, 0.75); }
.btn-primary { background-color: #4a77d4; background-image: -moz-linear-gradient(top, #6eb6de, #4a77d4); background-image: -ms-linear-gradient(top, #6eb6de, #4a77d4); background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#6eb6de), to(#4a77d4)); background-image: -webkit-linear-gradient(top, #6eb6de, #4a77d4); background-image: -o-linear-gradient(top, #6eb6de, #4a77d4); background-image: linear-gradient(top, #6eb6de, #4a77d4); background-repeat: repeat-x; filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4a77d4, GradientType=0);  border: 1px solid #3762bc; text-shadow: 1px 1px 1px rgba(0,0,0,0.4); box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.5); }
.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] { filter: none; background-color: #4a77d4; }
.btn-block { width: 100%; display:block; }

* { -webkit-box-sizing:border-box; -moz-box-sizing:border-box; -ms-box-sizing:border-box; -o-box-sizing:border-box; box-sizing:border-box; }

html { width: 100%; height:100%;  } */

/* body { 
  width: 100%;
  height:100%;
  font-family: 'Open Sans', sans-serif;
  background: #092756;
  background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top,  rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg,  #670d10 0%, #092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
  background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
  background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg,  #670d10 0%,#092756 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3E1D6D', endColorstr='#092756',GradientType=1 );
} */
.login { 
  
  position:absolute;
  /* top: 30%; */
  /* left: 50%; */
  /* margin: -150px 0 0 -150px; */
  width: 100%;
  height: 100%;
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg,  #670d10 0%,#092756 100%);


 
}

.signUp{
    position: fixed;
    width: 100%;
    height: 100%;
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg,  #670d10 0%,#092756 100%);
    /* height:600px; */
    
}

.signUpCard{
    border: 1px solid rgb(11, 11, 12);
    margin-left: 35%; 
    border: black;
    background-color: #c0c0c0;    
    width:550px;
    height: auto;
    overflow: visible;
}
.login h1 { color: #fff; text-shadow: 0 0 10px rgba(0,0,0,0.3); letter-spacing:1px; text-align:center; }
.signUp h1 { color: #fff; text-shadow: 0 0 10px rgba(0,0,0,0.3); letter-spacing:1px; text-align:center; }

.login input { 
  /* width: 100%;  */
 
  margin-bottom: 10px; 
  background: rgba(0,0,0,0.3);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100,100,100,0.2), 0 1px 1px rgba(255,255,255,0.2);
  transition: box-shadow .5s ease;
}
input:focus { box-shadow: inset 0 -5px 45px rgba(100,100,100,0.4), 0 1px 1px rgba(255,255,255,0.2); }

.signUp input { 
  /* width: 100%;  */
  margin-bottom: 10px; 
  background: rgba(0,0,0,0.3);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: rgb(7, 6, 6);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  background-color: #c0c0c0;    
  box-shadow: inset 0 -5px 45px rgba(100,100,100,0.2), 0 1px 1px rgba(255,255,255,0.2);
  transition: box-shadow .5s ease;
}
.signUp .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;

}
.login .Dropdown {
    width: 250px;
    margin: 15px;
}




.popup{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0,0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    border: black;

}
.popup-inner{
    position: relative;
    /* padding:10px; */
    width:auto;
    height: auto;
    background-color:#FFF;
    border: 1px solid rgba(0, 0, 0.3);
    border-radius: 4px;
 }
.popup-inner.close-btn{
    position:absolute;
    top: 16px;
    right:16px;
}
.popup__header {
    display: flex;
    justify-content: space-between;
    /* Border */
    border-bottom: 1px solid rgba(0, 0, 0.3);
    background-color: #eff5f46b;
}
.popup__footer {
    display: flex;
    /* Push the buttons to the right */
    justify-content: flex-end;
    background-color: #eff5f46b;
    border-top: 1px solid rgba(0, 0, 0.3);
}

.popup-content{
    text-align: left;
    padding: 10px;
    background-color: #eff5f46b;
}
.popup-content .Dropdown{
    width: auto;
    margin: 5px;
    margin-bottom: 5px;
    display:flex;
}
.popup-content input{
    width: auto;
    margin: 5px;
    margin-bottom: 5px;
    display:flex;
}


.spinBar .dialog-demo .p-button {
    margin: 0 .5rem 0 0;
    background-color: #424242b2 !important;
    opacity: unset !important;
    /* background-color: hsla(0, 2%, 30%, 0.89); */
}
.spinBar .p-dialog {
    /* display: flex; */
    flex-direction: column;
    pointer-events: auto;
    max-height: 90%;
    -webkit-transform: scale(1);
            transform: scale(1);
    position: relative;
    background: none;
    box-shadow: none;
}
.spinBar .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background:none;
    color: #495057;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    /* opacity: 0 ;  */
}
.spinBar .p-dialog-content {
     overflow-y: hidden; 
     overflow-x: hidden;
    flex-grow: 1;
}

.spinBar .p-dialog .p-dialog-content {
    /* background: #ffffff; */
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;
    background: none;
    /* opacity: 0; */
}
.card {
  margin-bottom: 2rem;
}
.p-datatable-gridlines{
  margin-right: 10px;
  margin-left: 10px;
}
.GenarateId{
  margin-top:-50px;
  margin-right:300px;
 
}
.p-column-filter {
width: 100%;
}
.GroupMasterUi .p-datatable .p-datatable-tbody > tr {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  /* height:12px; */
  /* padding: 2px; */
  color: black;
  
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  
}

.GroupMasterUi .p-datatable  {
  background-color: #BFC6C2;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  padding: 2px;
  border-radius: 8px;
}

.GroupMasterUi  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1 px 1px 1px 1px;
  margin :2px;
  padding :2px;
  color : black;
  font-family : Verdana;
  
}
.GroupMasterUi .p-datatable .p-datatable-tbody > tr > th {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height: 12px;
  padding: 2px;
  
}
.GroupMasterUi .p-datatable-gridlines{    
  
  justify-content: space-between;
  font-family: sans-serif;
  text-align: center;
}

.GroupMasterUi .DataTable .table-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn{
background-color:red;
border: none;
color: white;
padding: 12px 16px;
font-size: 16px;
cursor: pointer;
}


.GroupMaster .invalid-feedback{
color: red;
font-size: 16px;
margin:4px;
width:400px;
padding-bottom: 10px;
margin-left: 200px;

}

.button Button{
margin: 10px;
align-items: start;
height: 25px;
padding-bottom: 30px;

}
.GroupMasterUi .button{
align-items: unset;
margin-left: 200px;
height: 80px;
}


/* .GroupMaster{
text-align: start;
} */

/* .GroupMaster input{
width: 350px;
padding: 8px 10px;
margin: 15px;
box-sizing: border-box;

} */
.GroupMasterUi .GroupMaster label{
font-size: 13px;
font-family :Verdana;
color: rgb(39, 34, 34);
height: 20px;
width: 150px;
margin-top: 10px;
margin: 15px;
color: black;

text-align: left;
float:left;
}
.GroupMaster input{
width:400px;
height:30px;
padding: 8px 10px;
margin: 15px;
box-sizing: border-box;
border-radius: 6px;
border-color: lightgrey;

}
.GroupMasterUi .BackgroundColor{
/* background-color: rgb(117 117 129 / 94%); */
/* background: #c7caca; */
/* background: linear-gradient(90deg, rgb(206, 194, 194) 46%, rgb(231, 224, 224) 85%, rgb(227, 222, 222) 100%); */
background: linear-gradient(90deg, #c7bedb, #d9cfe6, #b7d6d0,#e3e1c7);

border: 3px solid rgb(10, 10, 10);
/* background-color: lightgray; */
height: 930px;
border-radius: 3%;
}
/* .GroupMasterUi .container {
display: flex;
width:50%;
}

.GroupMasterUi .container__half {
flex:1;
} */
.GroupMaster .grpname{
  width:700px;
}

.GroupMasterUi .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  /* border-width: 0 0 1px 0; */
  padding: 0.2rem 0.5rem;
  outline-color: #a6d5fa;
}
.SubGroupMaster select {
  width: 393px; 
   height: 30px;
  padding: 5px;
  margin-top: 3px;
  margin: 15px;
  text-align: left;
  border-radius: 6px;
  border-color: lightgray;
}
.dataTable .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
/* .p-datatable-gridlines{
    margin-right: 10px;
    margin-left: 10px;
}  */
.SubGroupMasterUi .BackgroundColor{
  /* background-color:  rgb(117 117 129 / 94%); */
  /* background: rgb(2,0,36); */
  background: linear-gradient(90deg, #c7bedb, #d9cfe6, #b7d6d0,#e3e1c7);
  /* background-color: lightgray; */
  border: 3px solid rgb(10, 10, 10);
  height: 930px;
  border-radius: 3%;

}
.SubGroupMasterUi .container {
  display: flex;
  align-items: center;
  
}

.SubGroupMasterUi .p-datatable .p-datatable-tbody > tr {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height:12px;
  padding: 2px;
  color: black;
}

.SubGroupMasterUi .p-datatable  {
  background-color: #BFC6C2;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  padding: 2px;
  border-radius: 8px;

}

.SubGroupMasterUi  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1 px 1px 1px 1px;
  margin :2px;
  padding :2px;
  color : black;
  font-family : Verdana;
  
}
.SubGroupMasterUi .p-datatable .p-datatable-tbody > tr > th {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height: 12px;
  padding: 2px;
}
.SubGroupMasterUi .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  /* border-width: 0 0 1px 0; */
  padding: 0.2rem 0.5rem;
  outline-color: #a6d5fa;
}
.SubGroupMasterUi .p-datatable-gridlines{    
  
  justify-content: space-between;
  font-family: sans-serif;
  text-align: center;
}

.SubGroupMasterUi .DataTable .table-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container__half {
  flex: 1 1;
}
.table-header .displayBtn {
  margin-left: 400px;
}

  
.button Button{
  margin: 40px;
  align-items: start;
  

}
.button{
  align-items: unset;
  margin-left: 200px;
}

  
/* .SubGroupMaster{
  text-align: start;
} */

.SubGroupMaster input{
  width: 400px;
  height: 30px;
  padding: 8px 10px;
  margin: 15px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
}
.SubGroupMaster label{
  font-size: 13px;
  color: rgb(39, 34, 34);
  height: 15px;
  width: 150px;
  font-family : Verdana;
  margin-top: 10px;
  margin: 15px;
  color :black;
  text-align: center;
  align-items: center;
  
  
  float:left;
  
}
.SubGroupMasterUi .SubGroupMaster .invalid-feedback{
  margin-left: 200px;
  font: 14px;
  color:red;
}
.p-blockui.p-component-overlay {
  opacity: 0;
}


.GL_Master{
  border: 3px solid rgb(10, 10, 10);   /* background-color: rgb(117 117 129 / 94%); */
   /* background-color: lightgray; */
  border-radius: 6px; 
  /* background: rgb(2,0,36);*/
  background: linear-gradient(90deg, #c7bedb, #d9cfe6, #b7d6d0,#e3e1c7);
  height: 930px;
  padding: 10px;
}
.GL_Master .p-datatable{
  background-color: #BFC6C2;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  padding: 2px;
  border-radius: 8px;
}

.GL_Master .p-datatable .p-datatable-tbody > tr {
  background: grey ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height: 12px;
  padding: 2px;
  color: black;
  }

  .GL_Master .selectStyle{
  height: 23px;

  }
  .GL_Master .label200{
    width: 180px;
    font-size: 12px;
    color: black;
    height: 20px;
    margin-top: 1px;
    float: left;
    margin: 4px;
    
   
    font-family: verdana
}
.GL_Master .invalid-feedback{
  color: red;
  font-size: 16px;
  margin:4px;
  width:400px;
  padding-bottom: 10px;
    }
  .button{
    margin-left:250px;
  
  }
  /* .btnEdit{
    background-color:rgb(55, 0, 255);
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 1100px;
  } */
 /* .Form_inline select {
    vertical-align: middle;
    margin: 5px 10px 5px 0;

} */
.GL_Master .table-header {
  display: flex;
  justify-content: space-between;
}
.gridStyle{
  -webkit-animation-name: p-datatable-sm;
          animation-name: p-datatable-sm;
}

@media (max-width: 480px) {
  .form {
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    margin: 15px 30px;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid lightgray;
   font-size: 2px;
  }

  .PersonalDetails label {

    font-size: 17px;
    height: 20px;
    width: 140px;
    margin-top: 10px;
    text-align: right;
    float: left;
  }

  .PersonalDetails .WingLabel {
    /* font-size: 18px; */
    color: #333;
    height: 20px;
    width: 73px;
    margin-top: 10px;
    text-align: right;
    float: left;
  }

  .PersonalDetails .Form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border-radius: 4px;
  }

  .PersonalDetails .Form-inline label {
    margin: 5px 10px 5px 0;
    width:65px;
    font-size: small;
  }

  .Form-inline input {
    vertical-align: middle;
    /* margin: 5px 10px 5px 0; */
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    width: 200px;

  }
  .SelectSmallWidth {
    width: 63px;
    padding: 4px 4px;
    margin: 4px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 5px;

  }
  input[type2=number] {
    width: 97px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  input[type3=text] {
    width: 550px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  .PersonalDetails [type4=text] {
    width: 178px;
    padding: 6px 4px;
    margin: 5px 10px 5px 0;
    box-sizing: border-box;
  }

  .PersonalDetails input.Date[type=date] {
    width: 86px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
  }

  input[type5=text] {
    width: 69px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  input[type6=text] {
    width: 200px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  .PersonalDetails [type7=text] {
    width: 240px;   
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    text-transform: capitalize;
  }

  .Select2 {
    width: 75px;
    margin: 10px;
    padding: 6px 4px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

  .Select3 {
    width: 200px;
    margin: 10px;
    padding: 6px 4px;
  }

  .Label1 {

    font-size: 18px;
    height: 20px;
    width: 250px;
    margin: 10px;
    text-align: right;
    margin-left: 50px;
    float: left;

  }

  .PersonalDetails .labelAdmition {

    /* font-size: 18px; */
    /* color: rgb(240, 12, 12); */
    height: 20px;
    width: 162px;
    /* margin-top: 10px; */

    text-align: right;

    float: left;

  }

  .PersonalDetails .SecondAddress {
    /* margin-left:120px; */
  }

  .PersonalDetails .number[type="number"] {
    width: 74px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  .PersonalDetails .EmailTextBox {
    width: 248px;
    height: 30px;
    display: flex;
  
  }

  .PersonalDetails .invalid-feedback {
    font-size: 16px;
    text-align: center;
    justify-content: center;
    color: red;
    margin: 10px;

  }
  .PersonalDetails .City {
    width: 70px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }
  .PersonalDetails .Form-inline .FlatNo {
    width: 61px;
  }
  .PersonalDetails .Mnumber{
    /* width: 75px; */
  }
  .PersonalDetails .Selectt2{
    width: 120px;
    margin: 10px;
    padding: 6px 4px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

}

@media(min-width: 1024px) {
  .form {
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    margin: 13px 26px;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid lightgray;
    background-color: lightgray;
  }

  .WingLabel {
    /* font-size: 18px; */
    color: #333;
    height: 20px;
    width: 120px;
    margin-top: 10px;
    text-align: right;
    float: left;
  }

  .PersonalDetails [type4=text] {
    width: 866px;
    padding: 6px 4px;
    margin: 5px 10px 5px 0;
    box-sizing: border-box;
    text-transform: capitalize;
  }

  input[type5=text] {
    width: 130px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .PersonalDetails input.number[type="number"] {
    width: 180px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .PersonalDetails input.Date[type=date] {
    width: 200px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: l1px solid #cccccc;
  }

  .Form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border-radius: 6px;
  }

  .PersonalDetails .Form-inline label {
    margin: 5px 10px 5px 0;
    width: 100px;
  }

  .PersonalDetails .Form-inline input {
    vertical-align: middle;
    /* margin: 5px 10px 5px 0; */
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    /* width: 200px; */
  }
  input[type5=text] {
    width: 100px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .Select2 {
    width: 235px;
    margin: 10px;
    padding: 6px 4px;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .Select3 {
    width: 200px;
    margin: 10px;
    padding: 6px 4px;
  }

  .PersonalDetails .Label1 {

    font-size: 18px;
    height: 20px;
    width: 250px;
    margin: 10px;
    text-align: right;
    margin-left: 50px;
    float: left;

  }

   label .labelAdmition {

    /* font-size: 18px; */
    /* color: rgb(240, 12, 12); */
    height: 20px;
    width: 200px;
    /* margin-top: 10px; */

    text-align: right;

    float: left;

  }

  .PersonalDetails .SecondAddress {
    /* margin-left:120px; */
  }

  .SelectSmallWidth {
    width: 100px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 6px;

  }

  input[type2=number] {
    width: 200px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .PersonalDetails .EmailTextBox {
    width: 463px;
    padding: 6px 4px;
    margin: 10px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    border: 1px solid #cccccc;
  }

  .PersonalDetails input[type7=text] {
    width: 973px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
    text-transform: capitalize;
  }

  .PersonalDetails .invalid-feedback {
    font-size: 16px;
    text-align: center;
    justify-content: center;
    color: red;
    margin: 10px;

  }

  .PersonalDetails .SmallLabel {
    width: 50px;

  }

  .PersonalDetails .City {
    width: 245px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
    text-transform: capitalize;
  }

  .PersonalDetails .btn {
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
    background-color: #4caf50;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    height: 37px;

  }
  .PersonalDetails .Selectt2{
    width: 235px;
    margin: 10px;
    padding: 6px 4px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    
  }
  
}
.Propertyform{
        color: lightgrey;
        /* border: 1px solid rgb(11, 11, 12); */
        background-color: lightgray;
        border-radius: 8px;
        
    
  }
  .Header{
    /* border: 1px solid rgb(11, 11, 12); */
    /* background-color: rgba(228, 239, 240, 0.548); */
    background-color: rgb(168, 219, 247);
    color: grey;
    margin-top: 5px;


  }
  .PropertyLabel{
    font-size: 13px;
    color: rgb(11, 11, 12);
    height: 20px;
    /* width: 100px; */
    margin-top: 10px;


    
    
    text-align: right;
    
    float:left;
}
.PropertySelect{
    width: 300px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
    height: 30px;
}
.Propertyform input[type8="text"]{
    width: 300px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    height: 30px;
  
}
.Propertyform input[type="number"]{
    width: 300px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    height: 30px;
}
.Propertyform .invalid-feedback{
    font-size: 16px;
    text-align: center;
    justify-content: center;    
    color: red;
    margin:10px;
}
.Propertyform .Form-inline label {
     margin: 5px 10px 5px 0; 
    width: 200px;
  }

  .Propertyform .Floorlabel{
    font-size: 13px;
    color: rgb(11, 11, 12);
    height: 20px;
    margin-left: 50px;
    margin-top: 10px;
  }
  .Propertyform .floor{
    height: 28px;
    border-radius: 6px;
    border-color: lightgrey;
    width: 300px;
    text-transform: capitalize;
  }
/* .split{
        height: 80%;
        width: 50%;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow-x: hidden;
        padding-top: 20px;
        margin-top:115px;
} */
 .OpeningBalance.left{
    /* width:50%;
    height:40%; */
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgray;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
  }
  .OpeningBalance.right{
    /* width:50%;
    right: 0;
    height:40%; */
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgrey;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
  }
  .OpeningBalance.down{
    /* width:100%;
    margin-top: 28%; */
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgray;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
  }
  .OpeningBalance .leftHeader{
      text-align: left;
      margin-top:-10px;
      /* border: 5px solid rgb(11, 11, 12); */
    background-color: rgb(168, 219, 247);
  }
  .OpeningBalance .rightHeader{
    text-align: left;
    margin-top:-10px;
    /* border: 5px solid rgb(11, 11, 12); */
    background-color: rgb(168, 219, 247);
}
  .DownHeader{
    /* border: 1px solid rgb(11, 11, 12); */
    background-color: rgb(168, 219, 247);
    text-align: left;
    margin-top:-10px;

  }
.OpeningBalance .OpeningBalanceLabel{
    font-size: 13px;
    color: rgb(11, 11, 12); 
    height: 20px;
    width: 250px;
    margin-top: 16px;
    margin-left: 15px;
    
    text-align: left;
    
    float:left;
    border-radius: 6px;
    border-color: lightgray;
}
.TenantDetailsLabel{
    font-size: 18px;
    color: rgb(11, 11, 12);
    height: 20px;
    width: 155px;
    margin-top: 10px;
    
    text-align: right;
    
    float:left;
}
.TenantSecondAddress{
    margin-left: 165px;
}
.TenantDetailsLabel2{
    font-size: 18px;
    color: rgb(11, 11, 12);
    height: 20px;
    width: 155px;
    margin-top: 10px;
    
    text-align: right;
    
    float:left;  
}
.OpeningBalance .OpeningBDate[type=Date]{
    width: 300px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
    height: 30px;
}
.OpeningBalance .OpeningBText[type=text]{
    width: 300px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
}
input.OpeningBText[type=number]{
  width: 300px;
  padding: 4px 2px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
}
.OpeningBalance .container {
  display: flex;
  justify-content: stretch;
}

.OpeningBalance .container__half {
  flex: 1 1;
  justify-content: stretch;
  text-align: left;
      margin-top:-10px;
      /* border: 5px solid rgb(11, 11, 12); */
      border: 1px solid rgb(11, 11, 12);
      background-color: rgb(229, 236, 232);
}

.OpeningBalance .amount{
  margin: 10px;
  width: 300px;
  height: 30px;
  border-radius: 6px;
  border-color: lightgray;
  
}
.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  /* background: lightgray; */
  padding: 0.5rem 0.5rem;
  border: 2px solid lightgray;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;
}

.NomineeForm{
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    padding: 10px;
  }
/* .MeetingDateLabel{
    width: 150px;

} */
.NomineeForm .SelectCity{
    width: 200px;
    margin: 10px;
    padding: 6px 4px;
    height:30px;

}
/* .BirthDateLabel{
    font-size: 18px;
    color: #333;
    height: 20px;
    width: 100px;
    margin-top: 10px;
    
    text-align: right;
    
    float:left;
  } */

  input.number[type="number"]{
    width: 240px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
}
.NomineeForm label{
  
  font-size: 13px;
   
    height: 20px;
    width: 100px;
    /* margin-top: 10px; */
    
    text-align: right;
    
    float:left;
    
}
.NomineeForm .labelAdmition {
  
  font-size: 13px;
   
    height: 20px;
    width: 200px;
    /* margin-top: 10px; */
    
    text-align: right;
    
    float:left;
    
}
.NomineeForm .MeetingDateLabel {
  
  font-size: 13px;
   
    height: 20px;
    width: 150px;
    /* margin-top: 10px; */
    
    text-align: right;
    
    float:left;
    
}
.NomineeForm .SecondAddress{
  margin-left: 110px;
}
.NomineeForm .invalid-feedback{
  font-size: 16px;
  text-align: center;
  justify-content: center;    
  color: red;
  margin:10px;
}
.NomineeForm .Date{
  height:30px;
    width:250px;
    margin:10px;
}

.p-blockui.p-component-overlay {
  opacity: 1;
}

/* .NomineeForm.selectedRow {
  background-color: rgba(6, 238, 45, 0.932) !important;
} */
.MaintenanceForm{
    /* border: 5px solid rgb(11, 11, 12); */
    background-color: rgb(28, 230, 213);
    border: lightgray;
    
}
.table-footer{
    margin-left: 800px;
}
.MaintenanceForm .Dropdown {
    width: 150px;
    margin: 15px;
}

.TenantDetails .invalid-feedback{
    font-size: 16px;
    text-align: center;
    justify-content: center;    
    color: red;
    margin: 10px;
  }
  .TenantDetails .BackgrdColor{
    background-color: lightgrey;
    border: black;
  }
  .TenantDetails .Date{
    height:30px;
    width:200px;
    margin:10px;
    border-radius: 6px;
    border-color: lightgray;

  }
  .highlighted-row {
    background-color: blue;
}

.selectedRow {
  /* background-color: rgba(6, 238, 45, 0.932) !important; */
  background-color: #93B88B !important;
}
.TenantDetails .dropdown {
  width: 200px;
    margin: 10px;
    padding: 6px 4px;
    border-radius: 6px;
    border-color: lightgray;
}

.TenantDetails .label{
  width:200px;
  margin: 10px;
  padding: 6px;
}
.TenantDetailsLabel .checkbox{
  margin: 60px;
  padding: 10px;
    border: 4px solid #ced4da;
    background: #ffffff;
    width: 50px;
    height: 30px;
    color: #495057;
    border-radius: 3px;
    margin-left: 20px;
}

.TenantDetails .EmailTextBox
{
  width:560px;
  height: 30px;
  display: flex;
  border-radius: 6px;
  border-color: lightgray;
}
.TenantDetailsLabel{   
  font-size: 13px;
   color: rgb(11, 11, 12);
   height: 20px;
   width: 170px;
   margin-top: 10px;
   text-align: right;
   float: left;
   
 }
 .TenantDetails .Mobile{
  font-size: 13px;
   color: rgb(11, 11, 12);
   height: 20px;
   width: 170px;
   margin-top: 10px;
   text-align: right;
   float: left;

 }

.TenantSecondAddress{
  margin-left: 169px;
  width:1500px;
  border-radius: 6px;
  border-color: lightgray;
  
}
input[type7=text]{
  width: 1100px;
  padding: 6px 4px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
   
}
.TenantDetails .tenantlbl{
  height: 28px;
  width: 990px;
  border-radius: 6px;
  border-color: lightgray;
  text-transform: capitalize;
}
input[type6=text]{
  width: 150px;
  padding: 6px 4px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
  height: 28px;
}

.TenantDetails .p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    /* background: #ffffff; */
    width: 30px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    /* margin-left: 10px; */
}
.TenantDetails .Form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 6px;
}
.TenantDetails .status{
  height: 28px;
  width: 200px;
  border-radius: 6px;
  border-color: lightgray; 
}
.TenantDetails .occupiedbyfld{
  height: 28px;
  width: 200px;
  border-radius: 6px;
  border-color: lightgray; 
  
}
.TenantDetails .SelectSmall{
  width: 105px;
  height:30px;
  border-radius: 6px;
  border-color: lightgray;
  margin: 8px;


}
.Vehicleform .VehicleLabel{
    font-size: 13px;
    color: rgb(11, 11, 12);
    width: 250px;
    margin-top: 10px;
    margin-left: 20px; 
   height: 20px;
   text-align: left;
   float: right;
}
.Vehicleform .VehicleSelect{
    width: 250px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
}
.Vehicleform{
  
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgray;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
    

}
.Vehicleform .table-header .btn{
    margin-left: 1100px;
}

.SocietyDetails_SocietyInfo__1l1L_ {
  background-color: rgb(229, 238, 233);
  /* border: 2px solid rgb(11, 11, 12); */
  border: lightgray;
}
.SocietyDetails_BillFootnote__89dx2 {
  padding: 20px;
}
.SocietyDetails_WingMaster__31cKQ {
  background-color: rgb(229, 238, 233);
  height: 470px;
  border: 2px solid rgb(11, 11, 12);
}
.SocietyDetails_TextBox__1hARy {
  width: 1000px;
  height: 27px;
  margin: 8px;
  box-sizing: border-box;
}
.SocietyDetails_SocietyName__2yVsn {
  margin-left: 35px;
}
.SocietyDetails_Form_inline__2khKw {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.SocietyDetails_Form_inline__2khKw label {
  margin: 5px 10px 5px 0;
}
.SocietyDetails_Form_inline__2khKw input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
}
.SocietyDetails_NumberTextBox__vQGyk {
  width: 580px;
  height: 27px;
  margin: 8px;
  box-sizing: border-box;
}
.SocietyDetails_ShortWidthDate__tBRuB {
  width: 200px;
  height: 27px;
  margin: 8px;

}
.SocietyDetails_SocietyCode__3yLoH {
  width: 210px;
  height: 27px;
  margin: 8px;
}
.SocietyDetails_ShortTextBox__nj2PA {
  width: 200px;
  height: 27px;
  margin: 8px;
}
.SocietyDetails_ShortNumberTextBox__-HhH_ {
  width: 200px;
  height: 27px;
  margin: 8px;
}
.SocietyDetails_EmailId__XJNB2 {
  width: 630px;
  height: 27px;
  margin: 8px;
}

.SocietyDetails_SelectMediumWidth__1n4nh {
  width: 200px;
  height: 27px;

}
.SocietyDetails_RateLabel__1BJE8 {
  margin-left: -40px;
}
.SocietyDetails_divider__3vwtZ {
  border: 5px solid rgb(11, 11, 12);
}
.SocietyDetails_LabelWidth200__114o9 {
  width: 210px;
  font-size: 13px;
  color: rgb(39, 34, 34);
  height: 20px;
  margin-top: 10px;
  text-align: right;
  float: left;
}
.SocietyDetails_checkboxLabel1__286Sl .SocietyDetails_Checkbox__1Wk9H {
  width: 15px;
  height: 25px;
}
.SocietyDetails_checkboxLabel__37QXZ {
  width: 95px;
  font-size: 16px;
  color: rgb(39, 34, 34);
}
.SocietyDetails_checkboxLabel__37QXZ .SocietyDetails_Checkbox__1Wk9H {
  width: 15px;
  height: 13px;
  /* margin-left: 10px; */

}
.SocietyDetails_checkboxLabel1__286Sl {
  width: 120px;
  font-size: 16px;
  color: rgb(39, 34, 34);
}
.SocietyDetails_RadiobuttonLabel__37JRC {
  width: 250px;
  text-align: center;
}
.SocietyDetails_button__1mWYc {
  margin-left: 400px;
  margin-top: 20px;
}
.SocietyDetails_Label__J4C3W {
  font-size: 13px;
  color: rgb(39, 34, 34);
  /* height: 20px; */
  width: 150px;
  margin-top: 10px;
  text-align: right;
  float: left;
}
.SocietyDetails_Select__3-DwT {
  width: 100px;
  height: 27px;
}
.SocietyDetails_WingMaster__31cKQ .SocietyDetails_tableheader__32MXI {
  justify-content: space-between;
  display: flex;
}
label {
  color: rgb(39, 34, 34);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  height: 13px;
}

.SocietyInfo {
    background-color: rgb(229, 238, 233);
    border: 2px solid rgb(11, 11, 12); 
  }
  .BillFootnote {
    padding: 20px;
  }
  .WingMaster {
    background-color: rgb(229, 238, 233);
    height: 470px;
  border: 2px solid rgb(11, 11, 12);
  }
  .TextBox {
    width: 1000px;
    height: 27px;
    margin: 8px;
    box-sizing: border-box;
  }
  .SocietyName {
    margin-left: 35px;
  }
  .Form_inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    
  }
  .Form_inline label {
    margin: 5px 10px 5px 0;
  }
  .Form_inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
  
  }
  .NumberTextBox {
    width: 580px;
    height: 27px;
    margin: 8px;
    box-sizing: border-box;
  }
  .ShortWidthDate {
    width: 200px;
    height: 27px;
    margin: 8px;
  }
  .SocietyCode {
    width: 210px;
    height: 27px;
    margin: 8px;
  }
  .ShortTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .ShortNumberTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .EmailId {
    width: 630px;
    height: 27px;
    margin: 8px;
  }
  
  .SelectMediumWidth {
    width: 200px;
    height: 27px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .RateLabel {
    margin-left: -40px;
  }
  .divider {
    border: 5px solid rgb(11, 11, 12); 
  }
  .LabelWidth200 {
    width: 210px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 10px;
    text-align: right;
    float: left;
  
  }
  .LabelWidth50 {
    width: 50px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 10px;
    text-align: left;
    
  
  }
  /* .checkboxLabel1{
    width: 150px;
    height: 25px;
  } */
  .checkboxLabel {
    width: 95px;
    font-size: 16px;
    color: rgb(39, 34, 34);
  }
  /* .checkboxLabel .Checkbox {
    width: 15px;
    height: 25px;
  } */
  .checkboxLabel1 {
    width: 140px;
    font-size: 16px;
    color: rgb(39, 34, 34);
  }
  .RadiobuttonLabel {
    width: 250px;
    text-align: center;
  }
  
  Button.label{
   transition: 'all .2s';
   margin-top:'15px';
   padding:'25px 15px';
   width:'130px';
   height:'30px';
  }
  .Select {
    width: 100px;
    height: 27px;
  }
  .WingMaster .tableheader {
    justify-content: space-between;
    display: flex;
  }
  .SocietyInfo.label {
    color: rgb(39, 34, 34);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    height: 13px;
  
  }
  .Slct2{
   height: 27px;
   width: 125px;
   border-radius: 6px;
   border-color: lightgray;
  }
  .checkboxLabel {
    width: 95px;
    font-size: 13px;
    color: rgb(39, 34, 34);  
  }
  .chkboxlabel{
    font-size: 13px;
    width: 120px;
    color: rgb(39, 34, 34);
  }
.width{
    width: 1000px;
}
.SupplimentaryBill{
margin: 5px;
padding: 5px;
}
.text-danger{

    color: red;
}
.SupplimentaryBill label{

    width:'200px';
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    margin-top: 6px;


}
.SupplimentaryBill input{

    width:'250px' !important;
    height:30px;
    border-radius: 6px;
    border-color: lightgray;
}

input[type="number"] {
    width:'250px' ;
    height:30px;

}
.invalid{
    border:1px solid #ff0000
    }
.SupplimentaryBill a{

    align-self: flex-start;
    text-align: left;
}

.SupplimentaryBill .date{
    width: 195px;
    height: 28px;
    border-radius: 6px;
    border-color: lightgray;
}
.SupplimentaryBill .selectBillFor{
    border-radius: 6px;
    border-color: lightgray;
    border-left-color: black;
    border-top-color: black;
    /* width:'250px'; */
     height:'28px' ;
}
.SupplimentaryBill{


}

.SupplimentaryBill  input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
  }
  .SupplimentaryBill .NumberTextBox {
    width: 580px;
    height: 27px;
    margin: 8px;
    box-sizing: border-box;
  }
  .SupplimentaryBill .ShortWidthDate {
    width: 200px;
    height: 27px;
    margin: 8px;
  }
  .SupplimentaryBill .SocietyCode {
    width: 210px;
    height: 27px;
    margin: 8px;
  }
  .SupplimentaryBill .ShortTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
  }
  .SupplimentaryBill .ShortNumberTextBox {
    width: 200px;
    height: 27px;
    margin: 8px;
  }

  .SupplimentaryBill .LabelWidth200 {
    width: 210px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 30px;
    margin-top: 10px;
    text-align: right;
    float: left;
    padding: 3px;
    margin: 3px;
  } 
  
  .SupplimentaryBill .SelectMediumWidth {
    width: 210px;
    font-size: 13px;
    color: rgb(39, 34, 34);
    height: 30px;
    margin-top: 10px;
    text-align: left;
    float: left;
    padding: 3px;
    margin: 3px;
  }
.GridView 
{

}
.btnEdit{
    background-color:rgb(55, 0, 255);
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  text-align: end;
 
  }

   .GridView .p-datatable .p-datatable-tbody > tr {
    background: linear-gradient(to bottom, #feffff 0%,#ddf1f9 35%);
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Verdana, sans-serif;
}

  .tableheader {
    display: flex;
    justify-content: space-between;

  }
  .tableheader label{
    display: flex;

    justify-content: flex-start;

  }
.memberReceipt .txt {
    width: 200px;
}

.memberReceipt .popup-content input {
    width: 120px;
    margin: 5px;
    margin-bottom: 5px;
    display: flex;
}

.memberReceipt .grid .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: 0.5rem 0.5rem;
    width: 100px;
}

.memberReceipt label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14px;
}

.memberReceipt Button {
    height: 30px;
    border-radius: 10px;
}

.dropdown-demo .p-dropdown {
    width: 100px;
    height: 25px;
}

.dropdown-demo .country-item-value img.flag {
    width: 17px;
}

.color {
    background-color: #E7ECDD;
    border-radius: 2%;
}

.MemerReceiptLabel {
    width: 180px;
    font-size: 18px;
    color: rgb(39, 34, 34); 
    height: 20px;
    margin-top: 5px;
    float: left;
    padding-left: 20px
}

.memberReceipt .MemerReceiptSmallLabel {
    width: 100px;
    font-size: 18px;
    color: rgb(39, 34, 34); 
    height: 20px;
    margin-top: 5px;
    float: left;
    padding-left: 20px
}

.memberReceipt .height {
    width: 150px;
    border-radius: 6px;
    border-color:lightgray;

}
.memberReceipt .Name{
    width:425px;
    height: 30px;
    border-radius: 6px;
    border-color:lightgray;

}

.memberReceipt .p-datatable .p-datatable-tbody > tr {
    background: linear-gradient(to bottom, #feffff 0%,#cbe6f0 35%);
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Verdana, sans-serif;
}
.memberReceipt .p-field {
    margin-bottom: 0.2rem;
}

.GeneralReceipt {
  background-color: #e7ecdd;
  border-radius: 2%;
  
}

.GeneralReceipt .label200 {
  width: 180px;
  font-size: 14px;
  color: rgb(39, 34, 34);
  height: 20px;
  margin-top: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  float: left;
  padding-left: 20px;
}

.popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}

.GeneralReceipt label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
}

.GeneralReceipt Button {
  height: 30px;
  border-radius: 10px;
}

.GeneralReceipt .label100 {
  width: 105px;
  color: rgb(39, 34, 34);
  height: 20px;
  margin-top: 5px;
  float: left;
  padding-left: 20px;
}

.GeneralReceipt .p-datatable .p-datatable-tbody > tr {
  background: linear-gradient(to bottom, #feffff 0%,#cbe6f0 35%);
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-family: Verdana, sans-serif;
}
.GeneralReceipt .height{
  width: 175px;
  borderRadius:6px;
   borderColor:lightgrey;
}
.GeneralReceipt .amount1
{
width: 175px;
border-radius: 6px;
border-color: lightgray;
height: 30px;
}
.GeneralReceipt .chq1{
  width: 175px;
border-radius: 6px;
border-color: lightgray;
height: 30px;
}
.GeneralReceipt .height2{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px;
}
.GeneralReceipt .p-formgroup-inline{
margin: -5px;
}
.GeneralReceipt .height3{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px;
}
.GeneralReceipt .smallTextBox{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px;
}
.SocietyView .Editbtn {
  background-color: rgb(55, 0, 255);
  border: none;
  color: white;

  font-size: 16px;
  cursor: pointer;
  margin-inline: 5px;
}
.SocietyView .AddBtn {
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;

}
.SocietyView .table-header {
  display: flex;
  justify-content: space-between;
  
}

.SocietyView .table-header label {
  margin-inline: 10px;
  color: grey;

}

.SocietyView .p-datatable .p-datatable-tbody > tr > td {
  background: linear-gradient(to bottom, #feffff 0%,#ddf1f9 35%);
  font-size: 12px;
  font-family: Verdana;
  margin: 2px;
  height: 12px;
  padding: 10px;
  color: black;
  overflow: hidden;
}

.bColor {
  background-color: #e7ecdd;
}

.MRReturnEntry Button {
  height: 30px;
  border-radius: 10px;
}

.MRReturnEntry label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  padding: 13;
  margin-top:5px;
  margin-left: 4px;
}

.popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}
.MRReturnEntry .smallTextBox{
  width: 175px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px; 

}
.backGroundColor {
  background-color: #E7ECDD;
}

.height {
  height: 30px;
}

.GRReturnEntry label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  margin-top:6px;
  margin-left: 4px;
}

.popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}

.GRReturnEntry Button {
  height: 30px;
  border-radius: 10px;
}
.GRReturnEntry .smallTextBox{
  width: 173px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px; 
}

@media screen and (min-width: 415px) {
  .site-container {
    flex-direction: row;
  }
}

.VoucherEntry {
  background-color: #e7ecdd;
}

.height {
  height: 30px;
}

.VoucherEntry input {
  margin: 5px;
}

.VoucherEntry select {
  margin: 5px;
}

.VoucherEntry label {
  font-size: 14px;
 color: rgb(39, 34, 34);
  height: 20px;
  width: 150px;
  margin: 6px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.VoucherEntry Button {
  height: 30px;
  border-radius: 10px;
}

.VoucherEntry .label100 {
  color: rgb(39, 34, 34);
  height: 20px;
  width: 100px;
  margin: 7px;
}
.VoucherEntry .smallTextBox{
  width: 173px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px; 
}
.BillGeneration {
  background-color: rgb(229, 238, 233);
}
@media screen and (min-width: 415px) {
  .site-container {
    flex-direction: row;
  }
}

.BillGeneration label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;

}
.BillGeneration Button {
  height: 30px;
  border-radius: 10px;
}

.billTable {
  width: 1200px;
}

.container {
  height: 100%;
}

.MediumTextBox {
  width: 390px;
  height: 25px;
}

.BillGeneration .p-col-fixed {
  padding: 20px;
  padding-left: 20px;
  width: 200px;
  margin: 0px;

}

.DateBox {
  width: 150px;
  height: 25px;
  border-radius: 6px;
  border-color: lightgray;
}

.TextBoxSize50 {
  width: 50px;
  height: 25px;
  /* border-radius: 6px; */

}

.BillGeneration .TextBoxSize100 {
  width: 100px;
  height: 25px;
}

.TextBoxSize150 {
  width: 150px;
  height: 25px;
  border-radius: 6px;
  border-color: lightgray;

}

.TextBoxSize200 {
  width: 200px;
  height: 25px;

}

.Form_inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 7px;

}

.Form_inline label {
  margin: 5px 10px 5px 0;
}

.BillGeneration .billForm .ValueLabel {
  height: 20px;
  width: 230px;
  margin-top: 10px;
  text-align: right;
  float: left;
}

.BillGeneration .billForm .Label {
  width: 40px;
  margin: 5px;
}

.ManualBillLabel {
  width: 140px;
}

.Label70 {
  width: 75px;
}

.BillGeneration .p-col {
  padding-right: 20px;
}

.BillGeneration .billForm label {
  width: 80px;
  margin: 5px;

}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% ;
    height: 1000px;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* font-size: 12px; */
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    font-size: 13px;
    border: 1px solid rgb(39 34 34);
    /* padding: 8px; */
  }
  
  /* tr:nth-child(even) {
    background-color: #dddddd;
  } */
  .SocietyName{
      text-align: center;
      margin-top: 20px;
      

  }
  .border{
    border-style: solid; 
    margin-left: 10px;
    margin-right: 10px;
    /* height:80px; */
    height:auto;
  }
  .alignleft {
    /* float: left; */
    /* text-align:left;*/
    margin-left: 10px; 
    display: inline-flex;
    justify-content: flex-start ;
  }
  .aligncenter {
    /* float: center; */
    /* width:33.33333%; */
    /* text-align:center; */
    display: inline-flex;
    justify-content: center;

    
  }
.alignright {
    float: right;
    
    margin-right: 10px;
    display:inline-flex;
    justify-content: flex-end ;
}
.Table{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
    
}
.BillFootNote{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
    padding: 4px;
}
.Receipt
{
  height: auto;
  margin-top: 2px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    padding: 4px;

}
/* .PageBreak {
  margin-top: 1rem;
    display: block;
    page-break-after: always;
} */

@media print {
  .PageBreak {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
    page-break-before: always;
  }
  
}
.mRRPageBreak{
  margin-top: 1rem;
  display: block;
  page-break-inside: avoid;

}
/* .RupeesInLetter{
  margin-right:60%;
  margin-bottom:-25px;
  font-size: 12px;
} */
.font{
  font-size:20px;
  font-weight: bold;
  
}
.Bill{
  text-align:center;
  font-size:20px;
  font-weight: bold;
}
.MemberBills .card{
 
  width:100%;
  /* background-color:rgba(238, 245, 244, 0.829) */
}
/* .MemberBillPrint .SelectionCard{
 
  background-Color: black;
  height: 45px;
} */
.DuesReminder{
  height:29.7cm;
}
.DuesReminder .Border{
  border-style:solid; 
    /* margin-left: 10px;
    margin-right: 10px;
     margin-top: 5px;
    margin-bottom: -35px;  */
    margin-top: 0.1cm;
    margin-bottom: 2cm;
    /* height: 32cm; */
    height:290mm;
    
    
   
    /* height:80px; */
}
.DuesReminder .font{
  font-size:20px;
  font-weight: bold;
}
.DuesReminder .fontsize{
  font-size:18px;
}
.DuesReminder .AlignContentEnd{
  text-align: end;
}
.DuesReminder .AlignContentCenter{
  text-align: center;
  
}
.DuesRegularTable{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border-style: solid; 
  font-size: 14px;
  height: auto;
}

.DuesAdvanceTable{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border: black solid 2px;
  font-size: 14px;
  height: auto;
}
.SummaryDues{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border-style: solid; 
  font-size: 14px;
  /* height: 297mm; */
  height:auto;
}

/* .page footer {
  font-size: 9px;
  color: #f00;
  text-align: center;
} */
.page footer {
  position: fixed;
  bottom: 0;
}

@page {

  size: A4;
  margin: 7mm 20mm 5mm 15mm;
  
 
}
@page :RegularBillRegister{

  size: landscape;
  margin: 7mm 20mm 5mm 15mm;
  
 
}

@media print {
  footer {
    position: fixed;
    bottom: 0;
  }


.pagebreak {
        page-break-after: always !important;
        -webkit-column-break-after: always !important;
                break-after: always !important;
        page-break-after: always !important;
        page-break-inside: avoid !important;
    }

  html, body {
    width: 210mm;
    height: 297mm;
    
  }
}
.DuesReminder .card{
  height:60px;
  border: black solid 3px;
  background-color: rgb(214, 220, 231);
}
.Date{
  height:30px;

}
.headerColor{
  background-color: rgb(177, 177, 173);
}
/* label{
  margin:10px;
   
} */

.MemberBill Button{
  height: 30px;
  border-radius: 10px;
}
.MemberBill input[type='Date']{
  height: 30px;
  margin-left: 10px;
  border-radius: 10px;
}
.MemberBill .card{
  background-color: rgb(48, 52, 55);
  height:7vh;
}

.header{
  width: 100%;
  /* position: fixed; */
  padding: 25px 0;
}
.footer{
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
}
.MemberBills .Receipt label{
  width: 250px;
}
  

.popup{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0,0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

}
.popup-inner{
    position: relative;
    /* padding:10px; */
    width:50%;
    height: auto;
    background-color:#FFF;
    border: 1px solid rgba(0, 0, 0.3);
    border-radius: 4px;
}
.popup-inner.close-btn{
    position:absolute;
    top: 16px;
    right:16px;
}
.popup__header {
    display: flex;
    justify-content: space-between;
    /* Border */
    border-bottom: 1px solid rgba(0, 0, 0.3);
}
.popup__footer {
    display: flex;
    /* Push the buttons to the right */
    justify-content: flex-end;
    /* Border */
    border-top: 1px solid rgba(0, 0, 0.3);
}

.popup-content{
    text-align: left;
    padding: 10px;
}
.spinner {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.spinner span {
  font-size: 2rem;
  -webkit-animation: fade 1s linear 0s infinite;
          animation: fade 1s linear 0s infinite;
  padding-right: 1rem;
}

.half-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #03fc4e;
  border-top: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: spin 0.5s linear 0s infinite;
          animation: spin 0.5s linear 0s infinite;
}

.completed {
  font-size: 2rem;
  color: #03fc4e;

  -webkit-animation: bigger 1s linear;

          animation: bigger 1s linear;
}

@-webkit-keyframes bigger {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

@keyframes bigger {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.creditDebitNote .container {
  display: flex;
  margin: -46px;
  margin-left: 8px;

}

.creditDebitNote .container__half {
  flex: 1 1;
}

.creditDebitNote .Debit {
  background-color: lightsalmon;
  border-radius: 2%;
  background: rgb(238,174,202);
  background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 70%);
  background: rgb(234,246,136);
  background: radial-gradient(circle, rgba(234,246,136,1) 47%, rgba(180,193,190,0.9559174011401436) 70%,  rgba(140,168,161,0.9559174011401436) 60%);
}

.creditDebitNote .popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}

.creditDebitNote .Credit {
  background-color: lightseagreen;
  border-radius: 2%;
  background: rgb(197,188,192);
  background: radial-gradient(circle, rgba(197,188,192,1) 47%, rgba(164,185,180,1) 71%, rgba(140,168,161,0.9559174011401436) 60%);

}

@media screen and (min-width: 415px) {
  .site-container {
    flex-direction: row;
  }
}

.creditDebitNote .inputTextBox {
  height: 30px;
  margin-bottom: -10px;
  border-radius: 6px;
  border-color: lightgray;
}

.creditDebitNote .Debit label {
  width: 100px;
    margin-top: 5px;
}

.creditDebitNote .Credit label {
  width: 100px;
  margin-top: 5px;

}

.creditDebitNote .debit label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;

}

.creditDebitNote .debit Button {
  height: 30px;
  border-radius: 10px;
}

.creditDebitNote .credit label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
}

.creditDebitNote .credit Button {
  height: 30px;
  border-radius: 10px;
}
.creditDebitNote .selectedRow {
  background-color: rgba(6, 238, 45, 0.932) !important;
}

/* .datatable .p-datatable .p-datatable-tbody > tr {
  background: linear-gradient(to bottom, #feffff 0%,#cbe6f0 35%);
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-family: Verdana, sans-serif
  .?
  */
.creditDebitNote .largeTextBox{
width: 485px;
height:60px;
border-radius: 6px;
border-color: lightgray;
}
.TransactionView .btnEdit {
  background-color: rgb(55, 0, 255);
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-inline: 5px;
}

.columnSize {
  width: auto;
}

.Dropdown {
  width: 50px;
}

.TransactionView .table-header {
  display: flex;
  justify-content: space-between;
  margin-inline: 5px;
}

.TransactionView label {
  font-weight: normal;
  justify-content: space-between;
  margin-inline: 5px;
}

.TransactionView .input {
  width: 150 px;
  padding: 2 px;
  margin: 2 px;
  border: '1 px solid';
  border-color: 'green';
  border-radius: 5 px;
}

.TransactionView .label {
  width: 150 px;
  padding: 15 px;
  margin: 15 px;
  border-radius: 5 px;
}

.TransactionView .p-datatable .p-datatable-tbody>tr {
  background: gray;
  background-color: #e0f0e8;
  font-size: 11px;
  font-family: Verdana;
  margin: 2px;
  height: 12px;
  padding: 2px;
  color: black;
}

.TransactionView td,th {
  border: 1px solid rgb(14, 3, 3);
  text-align: left;
  font-size: 13px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.dropdown-demo .p-dropdown {
  width: 14rem;
  
}

.dropdown-demo .country-item-value img.flag {
  width: 17px;
}


.signUp .invalid-feedback{
    width:400px;
    padding-bottom: 10px;
    margin-top:-25px;
    font-size: 14px;
    color: red;
    border: black;
}

.Users{
    background: radial-gradient(ellipse at center, #feffff 0%,#d2ebf9 100%);
}

.Users .p-datatable .p-datatable-tbody > tr {
    background: linear-gradient(to bottom, #feffff 0%,#ddf1f9 35%);
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Verdana, sans-serif;
}
.backgroundColor {
  background-color: #e7ecdd;
}

.backgroundColor label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  margin: 2px;
  padding: 3px;
  margin-left: 8px;
}

.Flat .container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex: 1 1 150px;
}

.tableheader {
  display: flex;
  justify-content: space-between;
}

.Flat .MDI {
  color: red !important;
}

.MR {
  color: blue !important;
}

.MDP {
  color: green !important;
}

.Flat .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;

}

.Flat .p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid grey;
  border-width: 0 0 1px 0;
  font-weight: 540;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s

}

.Flat .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}

.Flat .p-datatable .p-datatable-tbody > tr > td {
  text-align: right;
  border: 1px solid rgb(39 34 34);
  border-width: 0 0 1px 0;
  padding: 0.5rem 0.5rem;
  outline-color: #a6d5fa;

}
.Flat .p-datatable .p-datatable-thead > tr > th {
  text-align: center;
  padding: 0.5rem 0.5rem;
  border: 1px solid rgb(39 34 34);
  border-width: 0 0 1px 0;
  /* font-weight: 550; */
  color: #f6f8fa;
  /* font-size:large; */
  background: #828181;
  transition: box-shadow 0.2s;

}
.Flat .edit  {
  width: 1rem;
  padding: 0.8rem;
  height: 1rem;
  justify-content: center;
}
.Flat .p-button {
  color: rgb(39, 34, 34);
   background:white;
  border: 1px solid #f4f6f8;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  /* transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; */
  border-radius: 3px;
}


.Flat .btn{
  background-color:rgb(114, 143, 125);
  border: none;
  color: white;
  padding: 2px;
  margin: 2px;
  font-size: 13px;
  width: 40px;
  cursor: pointer;
  }
.Flat .compt{
  width: 119px;
}
.Flat .PropertySelct{
  width: 100px;
  border-radius: 6px;
  border-color: lightgray;
  height: 26px;
}
 



.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% ;
  height: 1000px;
}

.Society{
    text-align: center;
    margin-top: 10px;
}
.MRborder{
  border-style:solid; 
  margin-left: 10px;
  margin-right: 10px;
  height:435px;
}
.MRalignleft {
  float: left;
  text-align:left;
  margin-left: 10px;
  margin-top: 5px;
}
.MRalignright {
  float: right;   
  margin-right: 20px;
}
.MRRight{
  float: right;   
  margin-right: 20px;
}
.font{
font-size:20px;
font-weight: bold;
}
.MRBill{
text-align:center;
font-size:20px;
font-weight: bold;
margin-top: 10px;
}
.btnlabel{
margin-left: 10px;
}
.textHeight{
height: 30px;
margin-left: 10px;
}
.selection{
width: 150px;
height: 30px;
}
.memberReceiptReport label{
  width: 250px;
}
.alignRight{
  float: right;
    
    margin-right: 60px;
    display:inline-flex;
    justify-content: flex-end ;
}

.memberReceiptReport .Dropdown {
  width: 100px;
  margin: 15px;
  height:30px;
  margin-top: 18px;
}
@media print {
    .memberReceiptPageBreak {
    margin-top: 1rem;
    display: block; 
    /* page-break-after: always; */
    /* page-break-before: always; */
    page-break-inside: avoid;
  }
  
}


.btnApprove{
    height: 30px;
    background-color: green;
   
}
.btnDecline{
    height: 30px;
    background-color: red;
}
.PermissionTable .table-header{
    display: flex;
    justify-content: flex-end;
    
    
    
}


.profile .popup{
    position:fixed;
    top:30%;
    left:25%;
    width:25%;
    height:100%;
    border: 1px black;

    display: flex;
    justify-content: center;
    align-items: center;

}
.Profile .action {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}
.Profile .action.status-approved {
    background: #C8E6C9; 
    color: #256029;
}

.Profile .action.status-pending {
    background: #FFCDD2;
    color: #C63737;
}

.Profile .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem;
    border: solid #1c1e1f;
    border-width: 0 0 1px;
    font-weight: 600;
    color: #080a0c;
    background: #bcb7bc;
    transition: box-shadow .2s;
}



.popup{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0,0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

}
.popup-inner{
    position: relative;
    /* padding:10px; */
    width:30%;
    height: auto;
    background-color:#FFF;
    border: 1px solid rgba(0, 0, 0.3);
    border-radius: 4px;
}
.popup-inner.close-btn{
    position:absolute;
    top: 16px;
    right:16px;
}
.popup .popup__footer {
    display: flex;
    /* Push the buttons to the right */
    justify-content: center;
    /* Border */
    border-top: 1px solid rgba(0, 0, 0.3);
    padding: 5px;
}

.popup .popup-content{
    text-align: left;
    padding: 10px;
}
.popup label{
    width:100px;
}
.popup .textBoxWidth{
    width:250px;
}
.GL_MasterAuditView .table-header .fromDate{
   /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.GL_MasterAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.GL_MasterAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}

.GL_MasterAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}
.GroupMasterAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.GroupMasterAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';

    
}
.GroupMasterAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}

.GroupMasterAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
    
}


.Sub_GroupMasterAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.Sub_GroupMasterAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.Sub_GroupMasterAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}

.Sub_GroupMasterAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}
.SocietyMasterAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.SocietyMasterAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.SocietyMasterAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}

.SocietyMasterAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}
.personalAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
/* .personalAuditView .table-header .toDate{
    padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px;
} */
.personalAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.personalAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}

.personalAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}

.OpeningBalanceAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.OpeningBalanceAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.OpeningBalanceAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}
.OpeningBalanceAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}
.propertyAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.propertyAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.propertyAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}
.propertyAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}
.NomineeAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.NomineeAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.NomineeAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}
.NomineeAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}
.homepage .BackgroundColor{
    /* background-color: rgb(175, 152, 162); */
   
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(107,218,177,1) 85%, rgba(0,212,255,1) 100%);
    border: 3px solid black;
    height: 500px;
    border-radius: 2%;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
    }
    .product-badge.status-advance {
        background: #C8E6C9; 
        color: #256029;
    }
    
    .product-badge.status-dues {
        background: #FFCDD2;
        color: #C63737;
    }
    
    .product-badge.status-nill {
        background: #FEEDAF;
        color: #8A5340;
    }
.Bcolor{
    background-color: #E7ECDD;
    
}
.CommitteeMember .commiteeMemberLabel{
  
    font-size: 18px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    text-align: right;
    float:left;
}
.CommitteeMember .table-header .btn{
    margin-left: 1100px;
}
.Bcolor .Dropdown{
    width: 250px;
    margin: 3px;
    margin-bottom: 5px;
    display:flex;
    height: 35px;
   
}
.Bcolor .p-button {
    color: #ffffff;
    background: #2196F3;
    border: 1px solid #2196F3;
    padding: 1px;
    margin: 1px;
    font-size: 1rem;
    width: 80px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 5px;
}
.Bcolor .btn{
    background-color: red;
    width: 50px;
    height: 35px;
}
.Bcolor .label{
    
    margin-left: 5px;
    
   
}

.CommitteeMember .p-field.p-grid > label {
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 14px;
    width: 120px;
}
.BankReport .bankName {
  height: 30px;
}

.BankReport .Date {
  height: 30px;
}
.BankReport .card {
  height: 70px;
  border: lightgray solid 3px; 
  background-color: rgb(214, 220, 231);
  font-size: 13px;
}

.BankReport .BankReportData {
  margin-top: 30px;
}

.BankReport .p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid rgb(39 34 34); 
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.BankReport .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgb(39 34 34); 
  padding: 1rem 1rem;
  outline-color: #a6d5fa;
}

    
.PaymentReport .Mode{
    height: 30px;
    width:150px;
}
.PaymentReport .Operator{
    height: 30px;
    margin:10px;
    width:150px;

}
.PaymentReport .Label100{
   width: 150px;
    
}
.PaymentReport .text{
    height: 30px;
    margin: 10px;
}

.PaymentReport select{
    /* ; */
     
 }
.PaymentReport .Date{
    height:30px;

}
.PaymentReport .card{
    height:140px;
    border: black solid 3px;
    background-color: rgb(214, 220, 231);
}
.PaymentReport .BankReportData{
    margin-top: 30px;
}

.PaymentReport .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
    /* border-width: 0 0 1px 0; */
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.PaymentReport .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* border-width: 0 0 1px 0; */
    padding: 1rem 1rem;
    outline-color: #a6d5fa;
}
/* .BankReport .radio{
    height:30px;
} */



.TrialBalance label{
    margin:10px;
     
 }
 .TrialBalance .Date{
     height:30px;
 
 }
 .TrialBalance .TrialBalanceCard{
     margin: 2px;
     height:auto;
     border: black solid 3px;
     background-color: rgb(214, 220, 231);
 }
 .TrialBalance .ButtonCard{
     margin: 10px;
    height:2cm;
    border: black solid 2px;
    background-color: rgb(214, 220, 231);
}
.TrialBalance Button{
   margin-top: 15px;
}

 .TrialBalanceData .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.TrialBalanceData .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    padding: 1rem 1rem;
    outline-color: #a6d5fa;
}

.TrialBalanceData.p-datatable table {
    border-collapse: collapse;
    width: 100%;
    /* table-layout: fixed; */
}




.GLReport .GLReportCard {
    margin: 2px;
     height:auto;
     border: black solid 3px;
     background-color: rgb(214, 220, 231);
}
.GLReport .ButtonCard{
   margin: 5px;
   height: 3.5cm;
   border: black solid 2px;
   background-color: rgb(214, 220, 231);
}
/* .GLReport label{
    margin:10px;
     
 } */
 .GLReport .Date{
     height:30px;

 }
 .GLReport .number{
    height:30px;
    width: 100px;

}
.GLReport .title{
    height:30px;
    width: 400px;
    margin: 10px;

}
.GLReportCard .ButtonCard Button{
    height: 30px;
    border-radius: 10px;
}

.GLReportCard .ButtonCard label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14px;
}
.GLReportGrid .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
   
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.GLReportGrid .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
   font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1rem 1rem;
    outline-color: #a6d5fa;
}

.NominationRegisterTable{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
  }
  .IRegister{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: 320mm;
  }
  .IRegister .labelFont{
    font-size:15px;
    font-weight: bold;
    margin-top: 10px;
  }
  .IRegister .NomineeTable{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
  }
  .IRegister .hr {
    border-style: solid;
    border-width: 2px;
  }
  .IRegister .tableHeader {
    font-size:15px;
    font-weight: bold;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
.MemberOpeninBalanceNew{
    margin: 1px;
}
.MemberOpeningBalance .BackgroundColor {
    background-color: rgb(132, 126, 129);
    border: 3px solid black;
    height: 600px;
}
 .Changed {
 
   
    background-color: #b4b1b1 !important;
    
 
   
    border-radius: 3px;
}
.MemberOpeninBalance .p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid rgb(11 11 12);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
}

.MemberOpeningBalance .p-inputtext {
    margin: 0;
    width: 120px;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.MemberOpeninBalance .Form_inline label {
    width: 150px;
}

.MemberOpeningBalance .p-dropdown {
    background: #ffffff;
    border: 1px solid #262729;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    width: 120px;
}

.MemberOpeningBalance .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 1px 1px 1px 1px;
}

.MemberOpeningBalance .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.MemberOpeningBalance .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    border-width: 1px;
}

.MemberOpeningBalance .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    border-width: 0 0 1px 0;
    padding: 0.5rem 1rem;
    outline-color: #a6d5fa;
    /* background: #E2EBF0; */
}


.ComuSettings {
    border: 1px solid black;
    margin-left: 10px;
    max-height: 550px;
    max-width: 1000px;
}
.ComuSettings  label{
width: 150px;
align-items: center;
margin-left: 20px;
margin-right: 7px;
}

.ComuSettings .p-button {
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.ComuSettings .div {
    padding-top: 50px;
    padding-right: 30px;
    padding-bottom: 50px;
    padding-left: 80px;
    justify-content: center;
    align-items: center;
}
.ComuSettings .p-dropdown{
    justify-content: center;
    padding-left:space-between;
    margin-top: 20px;
}


.ComuSettings .p-checkbox{
    justify-content: center;
    align-items: center;
    margin: 5px;
    -webkit-margin-start: 1ex;
            margin-inline-start: 1ex;
    margin-left: 18px;
}

.ComuSettings .p-dropdown {
    display: inline-flex;
    margin: 5px;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
.BankFD {
    font-size: 14px;
    min-width: 400px;
    /* font-size: 14px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    float: left;
    padding-left: 20px; */

}
.BankFD2 {
    font-size: 14px;
    min-width: 400px;
    /* font-size: 14px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    float: left;
    padding-left: 20px; */

}

.BankFD label {
    display: inline-block;
    margin-left: 2px;
    font-size: 12px;
    margin: 15px;
    margin-bottom: 7px;
    padding: 2px;
    width: 130px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.BankFD  .p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid rgb(11 11 12);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 175px;
    border-radius: 3px;
}
.BankFD .p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: static;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
.BankFD  .p-calendar {
    width: 240px;
    height: 30px;
    position: relative;
    display: inline-flex;
    border-width: 2px;
    /* border-style: inset; */
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: none;
    border-image: initial;
}
/* .p-calendar .p-inputtext {
    /* flex: 1 1 auto; */
    /* width: 175px;
}  */

.BankFD .p-calendar .p-inputtext {
    /* flex: 1 1 auto; */
    /* width: 1%; */
    background-color: aliceblue;
}
/* .BankFD label {
    display: inline-block;
    margin: 2px;
    padding: 2px;
    width: 150px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
} */


.AllLabel {

    width: 250px;
}
.BankFD .height{
    width: 176px;
    height: 70px;
    border-radius: 6px;
    border-color: grey;
}
.BankFD .TextBox1{
    width: 176px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
}

.txt {
    width: 200px;
}

.popup-content input {
    width: 120px;
    margin: 5px;
    margin-bottom: 5px;
    display: flex;
}

.memberReceipt .grid .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: 0.5rem 0.5rem;
    width: 100px;
}

.memberReceipt label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
}

.memberReceipt Button {
    height: 30px;
    border-radius: 10px;
}

.dropdown-demo .p-dropdown {
    width: 100px;
    height: 25px;
}

.dropdown-demo .country-item-value img.flag {
    width: 17px;
}

.color {
    background-color: #E7ECDD;
    border-radius: 2%;
}

.MemerReceiptLabel {
    width: 180px;
    font-size: 18px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    float: left;
    padding-left: 20px
}

.MemerReceiptSmallLabel {
    width: 120px;
    font-size: 18px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    float: left;
    padding-left: 20px
}

.height {
    width: 175px;
}

.memberReceipt .p-datatable .p-datatable-tbody > tr {
    background: linear-gradient(to bottom, #feffff 0%,#cbe6f0 35%);
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Verdana, sans-serif;
}
.SupplimentaryMemberOpeninBalance .BackgroundColor {
    background-color: rgb(132, 126, 129);
    border: 3px solid grey;
    height: 600px;
    border-radius: 6px;
}

.SupplimentaryMemberOpeninBalance .p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid rgb(11 11 12);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
    border-radius: 6px;
    border-color: lightgray;

}

.SupplimentaryMemberOpeninBalance .p-inputtext {
    margin: 0;
    width: 120px;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.SupplimentaryMemberOpeninBalance .Form_inline label {
    width: 150px;
}

.SupplimentaryMemberOpeninBalance .p-dropdown {
    background: #ffffff;
    border: 1px solid #262729;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    width: 120px;
}

.SupplimentaryMemberOpeninBalance .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 1px 1px 1px 1px;
}

.SupplimentaryMemberOpeninBalance .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.SupplimentaryMemberOpeninBalance .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    border-width: 1px;
}

.SupplimentaryMemberOpeninBalance .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    border-width: 0 0 1px 0;
    padding: 0.5rem 1rem;
    outline-color: #a6d5fa;
    background: #E2EBF0;
}
.TrReview{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
  
  .TrReview label {
    margin: 5px 10px 5px 0;
  }
  .TrReview .Created {
    background-color: turquoise;
  }
  .TrReview .Missing {
    background-color: lightsalmon;
  }
  
.SupplimentaryBillGenerationPlan {
    margin: 5px;
    padding: 5px;
}

.text-danger {

    color: red;
}

.SupplimentaryBillGenerationPlan label {

    width: '200px';
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    margin-top: 5px;
}

.SupplimentaryBillGenerationPlan input {

    width: '250px' !important;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
}

.SupplimentaryBillGenerationPlan div {

margin: 2px;
}


input[type="number"] {
    width: '250px';
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
    

}
input[type="date"] {
    width: '250px';
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;

}

.invalid {
    border: 1px solid #ff0000
}

.SupplimentaryBillGenerationPlan a {

    align-self: flex-start;
    text-align: left;
}
.BillGenerationForIndividualFlat{

}

.BillGenerationForIndividualFlat .labelWidth{

    width: 200px;
}

@media (max-width: 480px) {
    .UpdateCommunicationDetail .btnn {
        display: 'flex';
        justify-content: 'center';
        margin: '20px';
        align-items: 'center'; 
    }
    .UpdateCommunicationDetail DataTable{
        /* width:200px; */
        padding:5px;
    }

    .UpdateCommunicationDetail .Changed {
        background-color: #af9f9f !important;
        border-radius: 3px;
    }
    .UpdateCommunicationDetail {
        flex-direction: column;
        align-items: start;
        background-color: rgb(36, 28, 28);
    }

    .UpdateCommunicationDetail ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }
}

/* @media (min-width: 1536px) {
    UpdateCommunicationDetail.btnn 
    {
        display:'flex';
        justifyContent:'center';
        margin:'20px';
        alignItems:'center';
        }
        UpdateCommunicationDetail 
        {
          flex-direction: column;
          align-items: start;
        }
        UpdateCommunicationDetail ul
         {
          display: none;
          flex-direction: column;
          width: 100%;
          margin-bottom: 0.25rem;
        }  
} */

/* .UpdateCommunicationDetail .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    text-align: center;
    background-color: white;  
    color: black;     
} */
.UpdateCommunicationDetail .btnn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    text-align: center;
    background-color: rgb(70, 228, 70);  
    color: black;  
    height: 30px;
}
.UpdateCommunicationDetail .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #151616f5;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #1bd6c1b8;
    transition: box-shadow 10s;
}
.BankDepositeSlip{
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    margin: 15px 30px;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid lightgray;
    /* font-size: 2px; */
} 

.BankDepositeSlip label {
    display: inline-block;
    font-size: 13px;
    height: 20px;
    margin: 7px;
    width: 90px;

    
    /* width: 100px; */
    /* margin-top: 10px;
    text-align: right;
    float: left; */
  }
  /* .BankDepositeSlip {
    display: flex;
    flex-flow: row wrap;

    border-radius: 4px;
  } */
  .BankDepositeSlip .largeTxtBox{
    width: 840px;
    border-radius: 6px;
    border-color: lightgray;
    margin: 4px;
    height: 25px;


  }
  .BankDepositeSlip .flatn{
    width: 70px;
  }
 
  .BankDepositeSlip .Name{
    width: 435px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
  }
  .BankDepositeSlip .datee{
    width: 148px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
    margin: 5px;
  }
  .BankDepositeSlip .rmno{
    width: 95px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
    margin: 5px;

  }
  .BankDepositeSlip .btnn{
    margin: 6px;
    height: 28px;
    
  }
  .BankDepositeSlip .btn{
    margin: 8px;
    height: 28px;
    margin-bottom: 30px;
  }
  .BankDepositeSlip .chqdate{
    height: 28px;
    width: 200px;

  }
  .BankDepositeSlip .chqno{
    height: 28px;
    width: 300px;
  }
  .BankDepositeSlip .fltn{
    width: 75px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray; 
  }
  
.BankDepositeSlip .amt{
    width: 150px;
    border-radius: 6px;
    border-color: lightgray;
    height: 25px;
}
.BankDepositeSlip .chqn{
    width: 150px;
    border-radius: 6px;
    border-color: lightgray;
    height: 25px;  
}  
.BankDepositeSlip .amtt{
    width: 94px;
}
.BankDepositeSlip .Select4{
    width: 40px;
    border-radius: 6px;
    border-color: lightgray;
    height: 25px;
    padding-left: 6px;
}




.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% ;
    height: 1000px;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* font-size: 12px; */
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    font-size: 13px;
    border: 1px solid rgb(39 34 34);
    /* padding: 8px; */
  }
  
  /* tr:nth-child(even) {
    background-color: #dddddd;
  } */
  .SocietyName{
      text-align: center;
      margin-top: 20px;
      

  }
  .border{
    border-style: solid; 
    margin-left: 10px;
    margin-right: 10px;
    /* height:80px; */
    height:auto;
  }
  .alignleft {
    /* float: left; */
    /* text-align:left;*/
    margin-left: 10px; 
    display: inline-flex;
    justify-content: flex-start ;
  }
  .aligncenter {
    /* float: center; */
    /* width:33.33333%; */
    /* text-align:center; */
    display: inline-flex;
    justify-content: center;

    
  }
.alignright {
    float: right;
    
    margin-right: 10px;
    display:inline-flex;
    justify-content: flex-end ;
}
.Table{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
    
}
.BillFootNote{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
    padding: 4px;
}
.Receipt
{
  height: auto;
  margin-top: 2px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    padding: 4px;

}
/* .PageBreak {
  margin-top: 1rem;
    display: block;
    page-break-after: always;
} */

@media print {
  .PageBreak {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
    page-break-before: always;
  }
  
}
.mRRPageBreak{
  margin-top: 1rem;
  display: block;
  page-break-inside: avoid;

}
/* .RupeesInLetter{
  margin-right:60%;
  margin-bottom:-25px;
  font-size: 12px;
} */
.font{
  font-size:20px;
  font-weight: bold;
  
}
.Bill{
  text-align:center;
  font-size:20px;
  font-weight: bold;
}
.MemberBills .card{
 
  width:100%;
  /* background-color:rgba(238, 245, 244, 0.829) */
}
/* .MemberBillPrint .SelectionCard{
 
  background-Color: black;
  height: 45px;
} */
.DuesReminder{
  height:29.7cm;
}
.DuesReminder .Border{
  border-style:solid; 
    /* margin-left: 10px;
    margin-right: 10px;
     margin-top: 5px;
    margin-bottom: -35px;  */
    margin-top: 0.1cm;
    margin-bottom: 2cm;
    /* height: 32cm; */
    height:290mm;
    
    
   
    /* height:80px; */
}
.DuesReminder .font{
  font-size:20px;
  font-weight: bold;
}
.DuesReminder .fontsize{
  font-size:18px;
}
.DuesReminder .AlignContentEnd{
  text-align: end;
}
.DuesReminder .AlignContentCenter{
  text-align: center;
  
}
.DuesRegularTable{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border-style: solid; 
  font-size: 14px;
  height: auto;
}

.DuesAdvanceTable{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border: black solid 2px;
  font-size: 14px;
  height: auto;
}
.SummaryDues{
  margin-top: 10px;
  margin-left:10px;
  margin-right: 10px;
  border-style: solid; 
  font-size: 14px;
  /* height: 297mm; */
  height:auto;
}

/* .page footer {
  font-size: 9px;
  color: #f00;
  text-align: center;
} */
.page footer {
  position: fixed;
  bottom: 0;
}

@page {

  size: A4;
  margin: 7mm 20mm 5mm 15mm;
  
 
}
@page :RegularBillRegister{

  size: landscape;
  margin: 7mm 20mm 5mm 15mm;
  
 
}

@media print {
  footer {
    position: fixed;
    bottom: 0;
  }


.pagebreak {
        page-break-after: always !important;
        -webkit-column-break-after: always !important;
                break-after: always !important;
        page-break-after: always !important;
        page-break-inside: avoid !important;
    }

  html, body {
    width: 210mm;
    height: 297mm;
    
  }
}
.DuesReminder .card{
  height:60px;
  border: black solid 3px;
  background-color: rgb(214, 220, 231);
}
.Date{
  height:30px;

}
.headerColor{
  background-color: rgb(177, 177, 173);
}
/* label{
  margin:10px;
   
} */

.MemberBill Button{
  height: 30px;
  border-radius: 10px;
}
.MemberBill input[type='Date']{
  height: 30px;
  margin-left: 10px;
  border-radius: 10px;
}
.MemberBill .card{
  background-color: rgb(48, 52, 55);
  height:7vh;
}

.header{
  width: 100%;
  /* position: fixed; */
  padding: 25px 0;
}
.footer{
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
}
.MemberBills .Receipt label{
  width: 250px;
}
  

