.BankReport .bankName {
  height: 30px;
}

.BankReport .Date {
  height: 30px;
}
.BankReport .card {
  height: 70px;
  border: lightgray solid 3px; 
  background-color: rgb(214, 220, 231);
  font-size: 13px;
}

.BankReport .BankReportData {
  margin-top: 30px;
}

.BankReport .p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid rgb(39 34 34); 
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.BankReport .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgb(39 34 34); 
  padding: 1rem 1rem;
  outline-color: #a6d5fa;
}
