.GridView 
{

}
.btnEdit{
    background-color:rgb(55, 0, 255);
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  text-align: end;
 
  }

   .GridView .p-datatable .p-datatable-tbody > tr {
    background: linear-gradient(to bottom, #feffff 0%,#ddf1f9 35%);
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Verdana, sans-serif;
}

  .tableheader {
    display: flex;
    justify-content: space-between;

  }
  .tableheader label{
    display: flex;

    justify-content: flex-start;

  }