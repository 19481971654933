.backgroundColor {
  background-color: #e7ecdd;
}

.backgroundColor label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  margin: 2px;
  padding: 3px;
  margin-left: 8px;
}

.Flat .container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex: 1 1 150px;
}

.tableheader {
  display: flex;
  justify-content: space-between;
}

.Flat .MDI {
  color: red !important;
}

.MR {
  color: blue !important;
}

.MDP {
  color: green !important;
}

.Flat .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;

}

.Flat .p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid grey;
  border-width: 0 0 1px 0;
  font-weight: 540;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s

}

.Flat .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}

.Flat .p-datatable .p-datatable-tbody > tr > td {
  text-align: right;
  border: 1px solid rgb(39 34 34);
  border-width: 0 0 1px 0;
  padding: 0.5rem 0.5rem;
  outline-color: #a6d5fa;

}
.Flat .p-datatable .p-datatable-thead > tr > th {
  text-align: center;
  padding: 0.5rem 0.5rem;
  border: 1px solid rgb(39 34 34);
  border-width: 0 0 1px 0;
  /* font-weight: 550; */
  color: #f6f8fa;
  /* font-size:large; */
  background: #828181;
  transition: box-shadow 0.2s;

}
.Flat .edit  {
  width: 1rem;
  padding: 0.8rem;
  height: 1rem;
  justify-content: center;
}
.Flat .p-button {
  color: rgb(39, 34, 34);
   background:white;
  border: 1px solid #f4f6f8;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  /* transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; */
  border-radius: 3px;
}


.Flat .btn{
  background-color:rgb(114, 143, 125);
  border: none;
  color: white;
  padding: 2px;
  margin: 2px;
  font-size: 13px;
  width: 40px;
  cursor: pointer;
  }
.Flat .compt{
  width: 119px;
}
.Flat .PropertySelct{
  width: 100px;
  border-radius: 6px;
  border-color: lightgray;
  height: 26px;
}
 


