.SocietyMasterAuditView .table-header .fromDate{
    /* padding: 5px 5px 5px 5px;
    height: 30px;
    border-radius: 15px; */
    height:35px;
    padding: 6 2 6 2;
    margin: 2;
    margin-right: 10;
    border-style :'solid';
    border-width: 1;
    border-color: 'green';
    border-radius: 5
}
.SocietyMasterAuditView .table-header .btnFetch{
    border-radius: 5;
    height: 35px;
    width: 100px;
    margin-inline: 10px;
    padding: 6 2 6 2;
    margin: 2;
    margin-left: 10px;
    border-style: 'solid';
    border-width: 1;
    border-color: 'green';
}
.SocietyMasterAuditView .table-header .p-input-icon-left{
    /* padding: 5px 5px 5px 5px; */
}

.SocietyMasterAuditView .table-header .label{
    width: 150;
    margin-left:20px;
    font-weight: normal;
    justify-content: space-between;
    margin-inline: 5px;
    margin-top: 5px;
    margin-right: 10px;
}