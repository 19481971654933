.GL_Master{
  border: 3px solid rgb(10, 10, 10);   /* background-color: rgb(117 117 129 / 94%); */
   /* background-color: lightgray; */
  border-radius: 6px; 
  /* background: rgb(2,0,36);*/
  background: linear-gradient(90deg, #c7bedb, #d9cfe6, #b7d6d0,#e3e1c7);
  height: 930px;
  padding: 10px;
}
.GL_Master .p-datatable{
  background-color: #BFC6C2;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  padding: 2px;
  border-radius: 8px;
}

.GL_Master .p-datatable .p-datatable-tbody > tr {
  background: grey ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height: 12px;
  padding: 2px;
  color: black;
  }

  .GL_Master .selectStyle{
  height: 23px;

  }
  .GL_Master .label200{
    width: 180px;
    font-size: 12px;
    color: black;
    height: 20px;
    margin-top: 1px;
    float: left;
    margin: 4px;
    
   
    font-family: verdana
}
.GL_Master .invalid-feedback{
  color: red;
  font-size: 16px;
  margin:4px;
  width:400px;
  padding-bottom: 10px;
    }
  .button{
    margin-left:250px;
  
  }
  /* .btnEdit{
    background-color:rgb(55, 0, 255);
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 1100px;
  } */
 /* .Form_inline select {
    vertical-align: middle;
    margin: 5px 10px 5px 0;

} */
.GL_Master .table-header {
  display: flex;
  justify-content: space-between;
}
.gridStyle{
  animation-name: p-datatable-sm;
}
