.card {
  margin-bottom: 2rem;
}
.p-datatable-gridlines{
  margin-right: 10px;
  margin-left: 10px;
}
.GenarateId{
  margin-top:-50px;
  margin-right:300px;
 
}
.p-column-filter {
width: 100%;
}
.GroupMasterUi .p-datatable .p-datatable-tbody > tr {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  /* height:12px; */
  /* padding: 2px; */
  color: black;
  
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  
}

.GroupMasterUi .p-datatable  {
  background-color: #BFC6C2;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  padding: 2px;
  border-radius: 8px;
}

.GroupMasterUi  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1 px 1px 1px 1px;
  margin :2px;
  padding :2px;
  color : black;
  font-family : Verdana;
  
}
.GroupMasterUi .p-datatable .p-datatable-tbody > tr > th {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height: 12px;
  padding: 2px;
  
}
.GroupMasterUi .p-datatable-gridlines{    
  
  justify-content: space-between;
  font-family: sans-serif;
  text-align: center;
}

.GroupMasterUi .DataTable .table-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn{
background-color:red;
border: none;
color: white;
padding: 12px 16px;
font-size: 16px;
cursor: pointer;
}


.GroupMaster .invalid-feedback{
color: red;
font-size: 16px;
margin:4px;
width:400px;
padding-bottom: 10px;
margin-left: 200px;

}

.button Button{
margin: 10px;
align-items: start;
height: 25px;
padding-bottom: 30px;

}
.GroupMasterUi .button{
align-items: unset;
margin-left: 200px;
height: 80px;
}


/* .GroupMaster{
text-align: start;
} */

/* .GroupMaster input{
width: 350px;
padding: 8px 10px;
margin: 15px;
box-sizing: border-box;

} */
.GroupMasterUi .GroupMaster label{
font-size: 13px;
font-family :Verdana;
color: rgb(39, 34, 34);
height: 20px;
width: 150px;
margin-top: 10px;
margin: 15px;
color: black;

text-align: left;
float:left;
}
.GroupMaster input{
width:400px;
height:30px;
padding: 8px 10px;
margin: 15px;
box-sizing: border-box;
border-radius: 6px;
border-color: lightgrey;

}
.GroupMasterUi .BackgroundColor{
/* background-color: rgb(117 117 129 / 94%); */
/* background: #c7caca; */
/* background: linear-gradient(90deg, rgb(206, 194, 194) 46%, rgb(231, 224, 224) 85%, rgb(227, 222, 222) 100%); */
background: linear-gradient(90deg, #c7bedb, #d9cfe6, #b7d6d0,#e3e1c7);

border: 3px solid rgb(10, 10, 10);
/* background-color: lightgray; */
height: 930px;
border-radius: 3%;
}
/* .GroupMasterUi .container {
display: flex;
width:50%;
}

.GroupMasterUi .container__half {
flex:1;
} */
.GroupMaster .grpname{
  width:700px;
}

.GroupMasterUi .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  /* border-width: 0 0 1px 0; */
  padding: 0.2rem 0.5rem;
  outline-color: #a6d5fa;
}