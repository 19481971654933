    
.PaymentReport .Mode{
    height: 30px;
    width:150px;
}
.PaymentReport .Operator{
    height: 30px;
    margin:10px;
    width:150px;

}
.PaymentReport .Label100{
   width: 150px;
    
}
.PaymentReport .text{
    height: 30px;
    margin: 10px;
}

.PaymentReport select{
    /* ; */
     
 }
.PaymentReport .Date{
    height:30px;

}
.PaymentReport .card{
    height:140px;
    border: black solid 3px;
    background-color: rgb(214, 220, 231);
}
.PaymentReport .BankReportData{
    margin-top: 30px;
}

.PaymentReport .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
    /* border-width: 0 0 1px 0; */
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.PaymentReport .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* border-width: 0 0 1px 0; */
    padding: 1rem 1rem;
    outline-color: #a6d5fa;
}
/* .BankReport .radio{
    height:30px;
} */

