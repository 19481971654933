/* .split{
        height: 80%;
        width: 50%;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow-x: hidden;
        padding-top: 20px;
        margin-top:115px;
} */
 .OpeningBalance.left{
    /* width:50%;
    height:40%; */
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgray;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
  }
  .OpeningBalance.right{
    /* width:50%;
    right: 0;
    height:40%; */
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgrey;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
  }
  .OpeningBalance.down{
    /* width:100%;
    margin-top: 28%; */
    /* border: 1px solid rgb(11, 11, 12); */
    border: lightgray;
    background-color: rgb(229, 236, 232);
    border-radius: 8px;
  }
  .OpeningBalance .leftHeader{
      text-align: left;
      margin-top:-10px;
      /* border: 5px solid rgb(11, 11, 12); */
    background-color: rgb(168, 219, 247);
  }
  .OpeningBalance .rightHeader{
    text-align: left;
    margin-top:-10px;
    /* border: 5px solid rgb(11, 11, 12); */
    background-color: rgb(168, 219, 247);
}
  .DownHeader{
    /* border: 1px solid rgb(11, 11, 12); */
    background-color: rgb(168, 219, 247);
    text-align: left;
    margin-top:-10px;

  }
.OpeningBalance .OpeningBalanceLabel{
    font-size: 13px;
    color: rgb(11, 11, 12); 
    height: 20px;
    width: 250px;
    margin-top: 16px;
    margin-left: 15px;
    
    text-align: left;
    
    float:left;
    border-radius: 6px;
    border-color: lightgray;
}
.TenantDetailsLabel{
    font-size: 18px;
    color: rgb(11, 11, 12);
    height: 20px;
    width: 155px;
    margin-top: 10px;
    
    text-align: right;
    
    float:left;
}
.TenantSecondAddress{
    margin-left: 165px;
}
.TenantDetailsLabel2{
    font-size: 18px;
    color: rgb(11, 11, 12);
    height: 20px;
    width: 155px;
    margin-top: 10px;
    
    text-align: right;
    
    float:left;  
}
.OpeningBalance .OpeningBDate[type=Date]{
    width: 300px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
    height: 30px;
}
.OpeningBalance .OpeningBText[type=text]{
    width: 300px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border-color: lightgray;
}
input.OpeningBText[type=number]{
  width: 300px;
  padding: 4px 2px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
}
.OpeningBalance .container {
  display: flex;
  justify-content: stretch;
}

.OpeningBalance .container__half {
  flex: 1;
  justify-content: stretch;
  text-align: left;
      margin-top:-10px;
      /* border: 5px solid rgb(11, 11, 12); */
      border: 1px solid rgb(11, 11, 12);
      background-color: rgb(229, 236, 232);
}

.OpeningBalance .amount{
  margin: 10px;
  width: 300px;
  height: 30px;
  border-radius: 6px;
  border-color: lightgray;
  
}
.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  /* background: lightgray; */
  padding: 0.5rem 0.5rem;
  border: 2px solid lightgray;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;
}
