.btnApprove{
    height: 30px;
    background-color: green;
   
}
.btnDecline{
    height: 30px;
    background-color: red;
}
.PermissionTable .table-header{
    display: flex;
    justify-content: flex-end;
    
    
    
}

