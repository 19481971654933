.ComuSettings {
    border: 1px solid black;
    margin-left: 10px;
    max-height: 550px;
    max-width: 1000px;
}
.ComuSettings  label{
width: 150px;
align-items: center;
margin-left: 20px;
margin-right: 7px;
}

.ComuSettings .p-button {
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.ComuSettings .div {
    padding-top: 50px;
    padding-right: 30px;
    padding-bottom: 50px;
    padding-left: 80px;
    justify-content: center;
    align-items: center;
}
.ComuSettings .p-dropdown{
    justify-content: center;
    padding-left:space-between;
    margin-top: 20px;
}


.ComuSettings .p-checkbox{
    justify-content: center;
    align-items: center;
    margin: 5px;
    margin-inline-start: 1ex;
    margin-left: 18px;
}

.ComuSettings .p-dropdown {
    display: inline-flex;
    margin: 5px;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}