.homepage .BackgroundColor{
    /* background-color: rgb(175, 152, 162); */
   
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(107,218,177,1) 85%, rgba(0,212,255,1) 100%);
    border: 3px solid black;
    height: 500px;
    border-radius: 2%;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
    }
    .product-badge.status-advance {
        background: #C8E6C9; 
        color: #256029;
    }
    
    .product-badge.status-dues {
        background: #FFCDD2;
        color: #C63737;
    }
    
    .product-badge.status-nill {
        background: #FEEDAF;
        color: #8A5340;
    }