.VoucherEntry {
  background-color: #e7ecdd;
}

.height {
  height: 30px;
}

.VoucherEntry input {
  margin: 5px;
}

.VoucherEntry select {
  margin: 5px;
}

.VoucherEntry label {
  font-size: 14px;
 color: rgb(39, 34, 34);
  height: 20px;
  width: 150px;
  margin: 6px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.VoucherEntry Button {
  height: 30px;
  border-radius: 10px;
}

.VoucherEntry .label100 {
  color: rgb(39, 34, 34);
  height: 20px;
  width: 100px;
  margin: 7px;
}
.VoucherEntry .smallTextBox{
  width: 173px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px; 
}