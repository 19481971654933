.Bcolor{
    background-color: #E7ECDD;
    
}
.CommitteeMember .commiteeMemberLabel{
  
    font-size: 18px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    text-align: right;
    float:left;
}
.CommitteeMember .table-header .btn{
    margin-left: 1100px;
}
.Bcolor .Dropdown{
    width: 250px;
    margin: 3px;
    margin-bottom: 5px;
    display:flex;
    height: 35px;
   
}
.Bcolor .p-button {
    color: #ffffff;
    background: #2196F3;
    border: 1px solid #2196F3;
    padding: 1px;
    margin: 1px;
    font-size: 1rem;
    width: 80px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 5px;
}
.Bcolor .btn{
    background-color: red;
    width: 50px;
    height: 35px;
}
.Bcolor .label{
    
    margin-left: 5px;
    
   
}

.CommitteeMember .p-field.p-grid > label {
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 14px;
    width: 120px;
}