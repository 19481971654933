
.App .signUp{
  text-align: center;
}

.App .login{
  text-align: center;
}

.Nav{
  display: flex;
  justify-content: space-around;
  align-items:center;
  /* background: rgb(41, 40, 40); */
  background: linear-gradient(to top right, #336699 0%, #666699 100%);
  min-height: 7vh;
  color:white;
  
  font-family: ui-monospace;

}
.Menu{
  display: flex;
  justify-content: space-around;
  margin-left: 60%;
}

.card{
  margin-bottom: 0px;
  color:rgb(26, 24, 24);
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% ;
  height: 1000px;
}
.cardLogin {
  margin: 0 auto; 
  float: none; 
  margin-bottom: 10px; 
  width: 30rem ;
  margin-top: 5em;
  height: 20em;
  border-radius: 20;
  border: 2px solid black;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btnGoogle{
  background-color: red;
}
.p-column-filter{
  width:100%;
  height:27px;
}
.p-calendar {
  width: 240px;
  height: 30px;
  position: relative;
  display: inline-flex;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.disabled {
  opacity: 0.65;
cursor: not-allowed;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #1c1e1f;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #080a0c;
  background: #bcb7bc;
  transition: box-shadow 0.2s;
}
/* .splitLeft {
  height: 1000%;
  width: 1000%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
} */








/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
