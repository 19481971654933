.memberReceipt .txt {
    width: 200px;
}

.memberReceipt .popup-content input {
    width: 120px;
    margin: 5px;
    margin-bottom: 5px;
    display: flex;
}

.memberReceipt .grid .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: 0.5rem 0.5rem;
    width: 100px;
}

.memberReceipt label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14px;
}

.memberReceipt Button {
    height: 30px;
    border-radius: 10px;
}

.dropdown-demo .p-dropdown {
    width: 100px;
    height: 25px;
}

.dropdown-demo .country-item-value img.flag {
    width: 17px;
}

.color {
    background-color: #E7ECDD;
    border-radius: 2%;
}

.MemerReceiptLabel {
    width: 180px;
    font-size: 18px;
    color: rgb(39, 34, 34); 
    height: 20px;
    margin-top: 5px;
    float: left;
    padding-left: 20px
}

.memberReceipt .MemerReceiptSmallLabel {
    width: 100px;
    font-size: 18px;
    color: rgb(39, 34, 34); 
    height: 20px;
    margin-top: 5px;
    float: left;
    padding-left: 20px
}

.memberReceipt .height {
    width: 150px;
    border-radius: 6px;
    border-color:lightgray;

}
.memberReceipt .Name{
    width:425px;
    height: 30px;
    border-radius: 6px;
    border-color:lightgray;

}

.memberReceipt .p-datatable .p-datatable-tbody > tr {
    background: linear-gradient(to bottom, #feffff 0%,#cbe6f0 35%);
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Verdana, sans-serif;
}
.memberReceipt .p-field {
    margin-bottom: 0.2rem;
}
