@media (max-width: 480px) {
  .form {
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    margin: 15px 30px;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid lightgray;
   font-size: 2px;
  }

  .PersonalDetails label {

    font-size: 17px;
    height: 20px;
    width: 140px;
    margin-top: 10px;
    text-align: right;
    float: left;
  }

  .PersonalDetails .WingLabel {
    /* font-size: 18px; */
    color: #333;
    height: 20px;
    width: 73px;
    margin-top: 10px;
    text-align: right;
    float: left;
  }

  .PersonalDetails .Form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border-radius: 4px;
  }

  .PersonalDetails .Form-inline label {
    margin: 5px 10px 5px 0;
    width:65px;
    font-size: small;
  }

  .Form-inline input {
    vertical-align: middle;
    /* margin: 5px 10px 5px 0; */
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    width: 200px;

  }
  .SelectSmallWidth {
    width: 63px;
    padding: 4px 4px;
    margin: 4px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 5px;

  }
  input[type2=number] {
    width: 97px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  input[type3=text] {
    width: 550px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  .PersonalDetails [type4=text] {
    width: 178px;
    padding: 6px 4px;
    margin: 5px 10px 5px 0;
    box-sizing: border-box;
  }

  .PersonalDetails input.Date[type=date] {
    width: 86px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
  }

  input[type5=text] {
    width: 69px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  input[type6=text] {
    width: 200px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  .PersonalDetails [type7=text] {
    width: 240px;   
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    text-transform: capitalize;
  }

  .Select2 {
    width: 75px;
    margin: 10px;
    padding: 6px 4px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

  .Select3 {
    width: 200px;
    margin: 10px;
    padding: 6px 4px;
  }

  .Label1 {

    font-size: 18px;
    height: 20px;
    width: 250px;
    margin: 10px;
    text-align: right;
    margin-left: 50px;
    float: left;

  }

  .PersonalDetails .labelAdmition {

    /* font-size: 18px; */
    /* color: rgb(240, 12, 12); */
    height: 20px;
    width: 162px;
    /* margin-top: 10px; */

    text-align: right;

    float: left;

  }

  .PersonalDetails .SecondAddress {
    /* margin-left:120px; */
  }

  .PersonalDetails .number[type="number"] {
    width: 74px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
  }

  .PersonalDetails .EmailTextBox {
    width: 248px;
    height: 30px;
    display: flex;
  
  }

  .PersonalDetails .invalid-feedback {
    font-size: 16px;
    text-align: center;
    justify-content: center;
    color: red;
    margin: 10px;

  }
  .PersonalDetails .City {
    width: 70px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }
  .PersonalDetails .Form-inline .FlatNo {
    width: 61px;
  }
  .PersonalDetails .Mnumber{
    /* width: 75px; */
  }
  .PersonalDetails .Selectt2{
    width: 120px;
    margin: 10px;
    padding: 6px 4px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

}

@media(min-width: 1024px) {
  .form {
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    margin: 13px 26px;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid lightgray;
    background-color: lightgray;
  }

  .WingLabel {
    /* font-size: 18px; */
    color: #333;
    height: 20px;
    width: 120px;
    margin-top: 10px;
    text-align: right;
    float: left;
  }

  .PersonalDetails [type4=text] {
    width: 866px;
    padding: 6px 4px;
    margin: 5px 10px 5px 0;
    box-sizing: border-box;
    text-transform: capitalize;
  }

  input[type5=text] {
    width: 130px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .PersonalDetails input.number[type="number"] {
    width: 180px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .PersonalDetails input.Date[type=date] {
    width: 200px;
    padding: 4px 2px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: l1px solid #cccccc;
  }

  .Form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border-radius: 6px;
  }

  .PersonalDetails .Form-inline label {
    margin: 5px 10px 5px 0;
    width: 100px;
  }

  .PersonalDetails .Form-inline input {
    vertical-align: middle;
    /* margin: 5px 10px 5px 0; */
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    /* width: 200px; */
  }
  input[type5=text] {
    width: 100px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .Select2 {
    width: 235px;
    margin: 10px;
    padding: 6px 4px;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .Select3 {
    width: 200px;
    margin: 10px;
    padding: 6px 4px;
  }

  .PersonalDetails .Label1 {

    font-size: 18px;
    height: 20px;
    width: 250px;
    margin: 10px;
    text-align: right;
    margin-left: 50px;
    float: left;

  }

   label .labelAdmition {

    /* font-size: 18px; */
    /* color: rgb(240, 12, 12); */
    height: 20px;
    width: 200px;
    /* margin-top: 10px; */

    text-align: right;

    float: left;

  }

  .PersonalDetails .SecondAddress {
    /* margin-left:120px; */
  }

  .SelectSmallWidth {
    width: 100px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 6px;

  }

  input[type2=number] {
    width: 200px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
  }

  .PersonalDetails .EmailTextBox {
    width: 463px;
    padding: 6px 4px;
    margin: 10px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    border: 1px solid #cccccc;
  }

  .PersonalDetails input[type7=text] {
    width: 973px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
    text-transform: capitalize;
  }

  .PersonalDetails .invalid-feedback {
    font-size: 16px;
    text-align: center;
    justify-content: center;
    color: red;
    margin: 10px;

  }

  .PersonalDetails .SmallLabel {
    width: 50px;

  }

  .PersonalDetails .City {
    width: 245px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
    text-transform: capitalize;
  }

  .PersonalDetails .btn {
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
    background-color: #4caf50;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    height: 37px;

  }
  .PersonalDetails .Selectt2{
    width: 235px;
    margin: 10px;
    padding: 6px 4px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    
  }
  
}