.popup{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0,0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

}
.popup-inner{
    position: relative;
    /* padding:10px; */
    width:50%;
    height: auto;
    background-color:#FFF;
    border: 1px solid rgba(0, 0, 0.3);
    border-radius: 4px;
}
.popup-inner.close-btn{
    position:absolute;
    top: 16px;
    right:16px;
}
.popup__header {
    display: flex;
    justify-content: space-between;
    /* Border */
    border-bottom: 1px solid rgba(0, 0, 0.3);
}
.popup__footer {
    display: flex;
    /* Push the buttons to the right */
    justify-content: flex-end;
    /* Border */
    border-top: 1px solid rgba(0, 0, 0.3);
}

.popup-content{
    text-align: left;
    padding: 10px;
}