.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% ;
  height: 1000px;
}

.Society{
    text-align: center;
    margin-top: 10px;
}
.MRborder{
  border-style:solid; 
  margin-left: 10px;
  margin-right: 10px;
  height:435px;
}
.MRalignleft {
  float: left;
  text-align:left;
  margin-left: 10px;
  margin-top: 5px;
}
.MRalignright {
  float: right;   
  margin-right: 20px;
}
.MRRight{
  float: right;   
  margin-right: 20px;
}
.font{
font-size:20px;
font-weight: bold;
}
.MRBill{
text-align:center;
font-size:20px;
font-weight: bold;
margin-top: 10px;
}
.btnlabel{
margin-left: 10px;
}
.textHeight{
height: 30px;
margin-left: 10px;
}
.selection{
width: 150px;
height: 30px;
}
.memberReceiptReport label{
  width: 250px;
}
.alignRight{
  float: right;
    
    margin-right: 60px;
    display:inline-flex;
    justify-content: flex-end ;
}

.memberReceiptReport .Dropdown {
  width: 100px;
  margin: 15px;
  height:30px;
  margin-top: 18px;
}
@media print {
    .memberReceiptPageBreak {
    margin-top: 1rem;
    display: block; 
    /* page-break-after: always; */
    /* page-break-before: always; */
    page-break-inside: avoid;
  }
  
}

