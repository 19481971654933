.SubGroupMaster select {
  width: 393px; 
   height: 30px;
  padding: 5px;
  margin-top: 3px;
  margin: 15px;
  text-align: left;
  border-radius: 6px;
  border-color: lightgray;
}
.dataTable .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
/* .p-datatable-gridlines{
    margin-right: 10px;
    margin-left: 10px;
}  */
.SubGroupMasterUi .BackgroundColor{
  /* background-color:  rgb(117 117 129 / 94%); */
  /* background: rgb(2,0,36); */
  background: linear-gradient(90deg, #c7bedb, #d9cfe6, #b7d6d0,#e3e1c7);
  /* background-color: lightgray; */
  border: 3px solid rgb(10, 10, 10);
  height: 930px;
  border-radius: 3%;

}
.SubGroupMasterUi .container {
  display: flex;
  align-items: center;
  
}

.SubGroupMasterUi .p-datatable .p-datatable-tbody > tr {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height:12px;
  padding: 2px;
  color: black;
}

.SubGroupMasterUi .p-datatable  {
  background-color: #BFC6C2;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  padding: 2px;
  border-radius: 8px;

}

.SubGroupMasterUi  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1 px 1px 1px 1px;
  margin :2px;
  padding :2px;
  color : black;
  font-family : Verdana;
  
}
.SubGroupMasterUi .p-datatable .p-datatable-tbody > tr > th {
  background: gray ;
  background-color: #A1C7B3;
  font-size : 11px;
  font-family: Verdana ;
  margin: 2px;
  height: 12px;
  padding: 2px;
}
.SubGroupMasterUi .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  /* border-width: 0 0 1px 0; */
  padding: 0.2rem 0.5rem;
  outline-color: #a6d5fa;
}
.SubGroupMasterUi .p-datatable-gridlines{    
  
  justify-content: space-between;
  font-family: sans-serif;
  text-align: center;
}

.SubGroupMasterUi .DataTable .table-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container__half {
  flex: 1;
}
.table-header .displayBtn {
  margin-left: 400px;
}

  
.button Button{
  margin: 40px;
  align-items: start;
  

}
.button{
  align-items: unset;
  margin-left: 200px;
}

  
/* .SubGroupMaster{
  text-align: start;
} */

.SubGroupMaster input{
  width: 400px;
  height: 30px;
  padding: 8px 10px;
  margin: 15px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: lightgray;
}
.SubGroupMaster label{
  font-size: 13px;
  color: rgb(39, 34, 34);
  height: 15px;
  width: 150px;
  font-family : Verdana;
  margin-top: 10px;
  margin: 15px;
  color :black;
  text-align: center;
  align-items: center;
  
  
  float:left;
  
}
.SubGroupMasterUi .SubGroupMaster .invalid-feedback{
  margin-left: 200px;
  font: 14px;
  color:red;
}
.p-blockui.p-component-overlay {
  opacity: 0;
}

