.NomineeForm{
    border: 1px solid rgb(11, 11, 12);
    background-color: rgb(229, 236, 232);
    padding: 10px;
  }
/* .MeetingDateLabel{
    width: 150px;

} */
.NomineeForm .SelectCity{
    width: 200px;
    margin: 10px;
    padding: 6px 4px;
    height:30px;

}
/* .BirthDateLabel{
    font-size: 18px;
    color: #333;
    height: 20px;
    width: 100px;
    margin-top: 10px;
    
    text-align: right;
    
    float:left;
  } */

  input.number[type="number"]{
    width: 240px;
    padding: 6px 4px;
    margin: 10px;
    box-sizing: border-box;
}
.NomineeForm label{
  
  font-size: 13px;
   
    height: 20px;
    width: 100px;
    /* margin-top: 10px; */
    
    text-align: right;
    
    float:left;
    
}
.NomineeForm .labelAdmition {
  
  font-size: 13px;
   
    height: 20px;
    width: 200px;
    /* margin-top: 10px; */
    
    text-align: right;
    
    float:left;
    
}
.NomineeForm .MeetingDateLabel {
  
  font-size: 13px;
   
    height: 20px;
    width: 150px;
    /* margin-top: 10px; */
    
    text-align: right;
    
    float:left;
    
}
.NomineeForm .SecondAddress{
  margin-left: 110px;
}
.NomineeForm .invalid-feedback{
  font-size: 16px;
  text-align: center;
  justify-content: center;    
  color: red;
  margin:10px;
}
.NomineeForm .Date{
  height:30px;
    width:250px;
    margin:10px;
}

.p-blockui.p-component-overlay {
  opacity: 1;
}

/* .NomineeForm.selectedRow {
  background-color: rgba(6, 238, 45, 0.932) !important;
} */