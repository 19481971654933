.BankFD {
    font-size: 14px;
    min-width: 400px;
    /* font-size: 14px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    float: left;
    padding-left: 20px; */

}
.BankFD2 {
    font-size: 14px;
    min-width: 400px;
    /* font-size: 14px;
    color: rgb(39, 34, 34);
    height: 20px;
    margin-top: 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    float: left;
    padding-left: 20px; */

}

.BankFD label {
    display: inline-block;
    margin-left: 2px;
    font-size: 12px;
    margin: 15px;
    margin-bottom: 7px;
    padding: 2px;
    width: 130px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.BankFD  .p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid rgb(11 11 12);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 175px;
    border-radius: 3px;
}
.BankFD .p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: static;
    -webkit-user-select: none;
    user-select: none;
}
.BankFD  .p-calendar {
    width: 240px;
    height: 30px;
    position: relative;
    display: inline-flex;
    border-width: 2px;
    /* border-style: inset; */
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}
/* .p-calendar .p-inputtext {
    /* flex: 1 1 auto; */
    /* width: 175px;
}  */

.BankFD .p-calendar .p-inputtext {
    /* flex: 1 1 auto; */
    /* width: 1%; */
    background-color: aliceblue;
}
/* .BankFD label {
    display: inline-block;
    margin: 2px;
    padding: 2px;
    width: 150px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
} */


.AllLabel {

    width: 250px;
}
.BankFD .height{
    width: 176px;
    height: 70px;
    border-radius: 6px;
    border-color: grey;
}
.BankFD .TextBox1{
    width: 176px;
    height: 25px;
    border-radius: 6px;
    border-color: lightgray;
}
