
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    /* padding: 0.75rem 1rem; */
    border-radius: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    -webkit-user-select: none;
    user-select: none;
   
    
}
.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #060607;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #d32f2f;
}
.Nav .UiName{
    /* border: 2px solid white; */
      background-color:white;
      color: black;
      /* border-width: 10px; */
      /* width:30% */
}
.Nav .societyName{
    width: 50%;
}
.Nav{
    width: 100%;
}

.card .p-menubar .p-submenu-list{
    padding: 0.25rem 0;
   
    /* font-family: 'Times New Roman', Times, serif; */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    /* rgb(101,166,145); */
    border: 0 none;
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
    width: 12.5rem;
}

.card .p-menubar .p-menuitem-link .p-menuitem-text {
    color:black;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #080808;
    margin-right: 0.5rem;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #080808;
    margin-left: 0.5rem;
}
