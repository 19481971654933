
@media (max-width: 480px) {
    .UpdateCommunicationDetail .btnn {
        display: 'flex';
        justify-content: 'center';
        margin: '20px';
        align-items: 'center'; 
    }
    .UpdateCommunicationDetail DataTable{
        /* width:200px; */
        padding:5px;
    }

    .UpdateCommunicationDetail .Changed {
        background-color: #af9f9f !important;
        border-radius: 3px;
    }
    .UpdateCommunicationDetail {
        flex-direction: column;
        align-items: start;
        background-color: rgb(36, 28, 28);
    }

    .UpdateCommunicationDetail ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }
}

/* @media (min-width: 1536px) {
    UpdateCommunicationDetail.btnn 
    {
        display:'flex';
        justifyContent:'center';
        margin:'20px';
        alignItems:'center';
        }
        UpdateCommunicationDetail 
        {
          flex-direction: column;
          align-items: start;
        }
        UpdateCommunicationDetail ul
         {
          display: none;
          flex-direction: column;
          width: 100%;
          margin-bottom: 0.25rem;
        }  
} */

/* .UpdateCommunicationDetail .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    text-align: center;
    background-color: white;  
    color: black;     
} */
.UpdateCommunicationDetail .btnn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    text-align: center;
    background-color: rgb(70, 228, 70);  
    color: black;  
    height: 30px;
}
.UpdateCommunicationDetail .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #151616f5;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #1bd6c1b8;
    transition: box-shadow 10s;
}