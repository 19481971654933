.backGroundColor {
  background-color: #E7ECDD;
}

.height {
  height: 30px;
}

.GRReturnEntry label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  margin-top:6px;
  margin-left: 4px;
}

.popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}

.GRReturnEntry Button {
  height: 30px;
  border-radius: 10px;
}
.GRReturnEntry .smallTextBox{
  width: 173px;
  border-radius: 6px;
  border-color: lightgray;
  height: 30px; 
}

@media screen and (min-width: 415px) {
  .site-container {
    flex-direction: row;
  }
}
