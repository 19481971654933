.MaintenanceForm{
    /* border: 5px solid rgb(11, 11, 12); */
    background-color: rgb(28, 230, 213);
    border: lightgray;
    
}
.table-footer{
    margin-left: 800px;
}
.MaintenanceForm .Dropdown {
    width: 150px;
    margin: 15px;
}
