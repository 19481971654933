.creditDebitNote .container {
  display: flex;
  margin: -46px;
  margin-left: 8px;

}

.creditDebitNote .container__half {
  flex: 1;
}

.creditDebitNote .Debit {
  background-color: lightsalmon;
  border-radius: 2%;
  background: rgb(238,174,202);
  background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 70%);
  background: rgb(234,246,136);
  background: radial-gradient(circle, rgba(234,246,136,1) 47%, rgba(180,193,190,0.9559174011401436) 70%,  rgba(140,168,161,0.9559174011401436) 60%);
}

.creditDebitNote .popup-content input {
  width: 120px;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}

.creditDebitNote .Credit {
  background-color: lightseagreen;
  border-radius: 2%;
  background: rgb(197,188,192);
  background: radial-gradient(circle, rgba(197,188,192,1) 47%, rgba(164,185,180,1) 71%, rgba(140,168,161,0.9559174011401436) 60%);

}

@media screen and (min-width: 415px) {
  .site-container {
    flex-direction: row;
  }
}

.creditDebitNote .inputTextBox {
  height: 30px;
  margin-bottom: -10px;
  border-radius: 6px;
  border-color: lightgray;
}

.creditDebitNote .Debit label {
  width: 100px;
    margin-top: 5px;
}

.creditDebitNote .Credit label {
  width: 100px;
  margin-top: 5px;

}

.creditDebitNote .debit label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;

}

.creditDebitNote .debit Button {
  height: 30px;
  border-radius: 10px;
}

.creditDebitNote .credit label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
}

.creditDebitNote .credit Button {
  height: 30px;
  border-radius: 10px;
}
.creditDebitNote .selectedRow {
  background-color: rgba(6, 238, 45, 0.932) !important;
}

/* .datatable .p-datatable .p-datatable-tbody > tr {
  background: linear-gradient(to bottom, #feffff 0%,#cbe6f0 35%);
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-family: Verdana, sans-serif
  .?
  */
.creditDebitNote .largeTextBox{
width: 485px;
height:60px;
border-radius: 6px;
border-color: lightgray;
}