.TransactionView .btnEdit {
  background-color: rgb(55, 0, 255);
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-inline: 5px;
}

.columnSize {
  width: auto;
}

.Dropdown {
  width: 50px;
}

.TransactionView .table-header {
  display: flex;
  justify-content: space-between;
  margin-inline: 5px;
}

.TransactionView label {
  font-weight: normal;
  justify-content: space-between;
  margin-inline: 5px;
}

.TransactionView .input {
  width: 150 px;
  padding: 2 px;
  margin: 2 px;
  border: '1 px solid';
  border-color: 'green';
  border-radius: 5 px;
}

.TransactionView .label {
  width: 150 px;
  padding: 15 px;
  margin: 15 px;
  border-radius: 5 px;
}

.TransactionView .p-datatable .p-datatable-tbody>tr {
  background: gray;
  background-color: #e0f0e8;
  font-size: 11px;
  font-family: Verdana;
  margin: 2px;
  height: 12px;
  padding: 2px;
  color: black;
}

.TransactionView td,th {
  border: 1px solid rgb(14, 3, 3);
  text-align: left;
  font-size: 13px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.dropdown-demo .p-dropdown {
  width: 14rem;
  
}

.dropdown-demo .country-item-value img.flag {
  width: 17px;
}

