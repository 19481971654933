.NominationRegisterTable{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
  }
  .IRegister{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: 320mm;
  }
  .IRegister .labelFont{
    font-size:15px;
    font-weight: bold;
    margin-top: 10px;
  }
  .IRegister .NomineeTable{
    margin-top: 10px;
    margin-left:10px;
    margin-right: 10px;
    border-style: solid; 
    font-size: 14px;
    height: auto;
  }
  .IRegister .hr {
    border-style: solid;
    border-width: 2px;
  }
  .IRegister .tableHeader {
    font-size:15px;
    font-weight: bold;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }