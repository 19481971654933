
.TrialBalance label{
    margin:10px;
     
 }
 .TrialBalance .Date{
     height:30px;
 
 }
 .TrialBalance .TrialBalanceCard{
     margin: 2px;
     height:auto;
     border: black solid 3px;
     background-color: rgb(214, 220, 231);
 }
 .TrialBalance .ButtonCard{
     margin: 10px;
    height:2cm;
    border: black solid 2px;
    background-color: rgb(214, 220, 231);
}
.TrialBalance Button{
   margin-top: 15px;
}

 .TrialBalanceData .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid rgb(39 34 34);
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.TrialBalanceData .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    padding: 1rem 1rem;
    outline-color: #a6d5fa;
}

.TrialBalanceData.p-datatable table {
    border-collapse: collapse;
    width: 100%;
    /* table-layout: fixed; */
}



